.widget-svg:not(.multi-widget-selected):not(.widget-selected) {
  .widget {
    @media (prefers-color-scheme: dark) {
      fill: #2F3236 !important;
    }
  }
}

.widget-selected {
  .widget {
    @media (prefers-color-scheme: dark) {
      fill: #8D8E93 !important;
    }
  }
}

.widget-grad {
  stop:nth-child(1) {
    @media (prefers-color-scheme: dark) {
      stop-color: #8D8E93 !important;
    }
  }
  stop:nth-child(2) {
    @media (prefers-color-scheme: dark) {
      stop-color: #8D8E93 !important;
    }
  }
  stop:nth-child(3) {
    @media (prefers-color-scheme: dark) {
      stop-color: #2F3236 !important;
    }
  }
  stop:nth-child(4) {
    @media (prefers-color-scheme: dark) {
      stop-color: #2F3236 !important;
    }
  }

}

.app-details .container .comments .scroller .comment .comment-rate, .submit-review {
  .widget-ratings {
    direction: ltr;
  }

  .widget-svg:not(.multi-widget-selected):not(.widget-selected) {
    .widget {
      @media (prefers-color-scheme: dark) {
        fill: #2F3236 !important;
      }
    }
  }

  .widget-selected {
    .widget {
      @media (prefers-color-scheme: dark) {
        fill: #ff9502 !important;
      }
    }
  }

  .widget-grad {
    stop:nth-child(1) {
      @media (prefers-color-scheme: dark) {
        stop-color: #8D8E93 !important;
      }
    }
    stop:nth-child(2) {
      @media (prefers-color-scheme: dark) {
        stop-color: #8D8E93 !important;
      }
    }
    stop:nth-child(3) {
      @media (prefers-color-scheme: dark) {
        stop-color: #2F3236 !important;
      }
    }
    stop:nth-child(4) {
      @media (prefers-color-scheme: dark) {
        stop-color: #2F3236 !important;
      }
    }

  }
}

.rc-progress-line-trail {
  @media (prefers-color-scheme: dark) {
    stroke: #2D2E31 !important;
  }
}

.rc-progress-line-path {
  @media (prefers-color-scheme: dark) {
    stroke: #A2A2AB !important;
  }
}

.payment-options {
  .rc-progress-line-trail {
    @media (prefers-color-scheme: dark) {
      stroke: #2D2E31 !important;
    }
  }

  .rc-progress-line-path {
    @media (prefers-color-scheme: dark) {
      stroke: $color-primary !important;
    }
  }
}
