.app-details {
  .view-navigation-bar {
    .app-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
      background-color: $color-placeholder;
      border-radius: 12px;
      border: 1px solid $color-secondary;

      @media (prefers-color-scheme: dark) {
        background-color: $dark-mode-background-color;
        border-color: $dark-mode-separator-color;
      }
    }

    .button {
      position: absolute;
      right: 15px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      background: $color-primary;
      border: none;
      color: #fff;
      padding-left: 15px;
      padding-right: 15px;
      height: 25px;
      border-radius: 15px;
    }

    button[disabled], .button[disabled] {
      background-color: #8e8e93;
      color: #ffffff;
      // TODO: set dark mode color
    }
  }

  .container {
    padding-top: 60px;

    .basic-info {
      display: flex;
      justify-content: space-between;
      padding-right: 15px;
      padding-left: 15px;

      .app-items {
        display: flex;
        flex-direction: row;

        .app-icon {
          img {
            width: 112px;
            height: 112px;
            border-radius: 28px;
            border: solid 1px #f2f2f8;
            background-color: $color-placeholder;
            @media (prefers-color-scheme: dark) {
              background-color: $dark-mode-separator-color;
              border-color: $dark-mode-separator-color;
            }
          }
        }

        .details {
          display: flex;
          flex-direction: column;
          padding-right: 15px;
          margin-bottom: 10px;

          h1 {
            font-size: 24px;
            text-align: right;
          }

          p {
            font-size: 14px;
            color: $text-gray_color;
            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-gray-color;
            }
            margin-bottom: 10px;
          }

          .button {
            text-align: center;
            max-width: 75px;
            height: 30px;
            border-radius: 15px;
            border: none;
            color: #fff;
            font-size: 14px;
            margin-top: auto;
            min-width: 75px;
            background-color: $color-primary;
          }

          a.button {
            padding-top: 5px;
            height: 25px;
          }

          .button[disabled] {
            background-color: #8e8e93;
            color: #ffffff;
          }
        }
      }

      .share {
        align-self: flex-end;
        margin-bottom: 10px;

        .share-button {
          width: 30px;
          height: 30px;
          background: $color-primary;
          text-align: center;
          border: none;
          border-radius: 15px;
        }
      }
    }

    .app-rating {
      min-height: 56px;
      padding-right: 15px;
      padding-left: 15px;

      .rating {
        margin-top: 20px;
        display: flex;

        .rate {
          font-size: 24px;
          font-weight: 500;
          text-align: right;
          color: $text-gray_color;
          @media (prefers-color-scheme: dark) {
            color: $dark-mode-text-gray-color;
          }
        }

        .widget-ratings {
          direction: ltr;
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 6px;
        }
      }

      .rate-count {
        font-size: 14px;
        color: $text-gray_color;
        @media (prefers-color-scheme: dark) {
          color: $dark-mode-text-gray-color;
        }
      }
    }

    .screenshots {
      margin-top: 15px;

      .scroller {
        white-space: nowrap;
        overflow: scroll;
        margin-bottom: 20px;
        padding-right: 15px;
        padding-left: 15px;

        img {
          width: 206px;
          height: 366px;
          margin-left: 15px;
          border-radius: 8px;
          background-color: $color-placeholder;
          @media (prefers-color-scheme: dark) {
            background-color: $dark-mode-separator-color;
          }
        }

        img.landscape {
          min-height: calc((100vw - 15px) / 1.77);
          height: auto;
          width: calc(100% - 15px);
        }
      }

      .separator {
        margin: 0;
      }
    }

    .description {
      margin-top: 20px;
      padding-right: 15px;
      padding-left: 15px;

      p {
        font-size: 14px;
      }

      .more-button {
        color: $color-primary;
        display: block;
        text-align: left;
        font-weight: bold;
      }
    }

    .developer {
      padding-right: 15px;
      padding-left: 15px;

      .container {
        padding: 0;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .details {

          a {
            color: $color-primary;
            font-size: 17px;
            font-weight: bold;
          }

          p {
            font-size: 15px;
            color: $text-gray_color;
            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-gray-color;
            }
          }
        }

        a {
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      .separator {
        margin: 15px 0 0;
      }
    }

    .change-log {
      margin-top: 15px;
      padding-right: 15px;
      padding-left: 15px;

      .container {
        padding: 0;

        h3 {
          font-size: 18px;
          font-weight: 500;
        }

        .version-details {
          display: flex;
          justify-content: space-between;

          p {
            font-size: 15px;
            color: $text-gray_color;
            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-gray-color;
            }
          }
        }

        .change-log-text {
          margin-top: 10px;
          font-size: 15px;
        }

        .more-button {
          color: $color-primary;
          display: block;
          text-align: left;
          font-weight: bold;
        }

        .separator {
          margin: 20px 0 0;
        }
      }
    }

    .review-rating {
      padding-right: 15px;
      padding-left: 15px;

      .review-rating-container {
        direction: rtl;
        padding-bottom: 40px;

        h3 {
          font-size: 18px;
          margin-top: 20px;
        }

        .rating {
          float: left;
          width: 130px;

          .rate {
            font-size: 45px;
            text-align: center;
          }

          .rate-count {
            color: $text-gray_color;
            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-gray-color;
            }
            font-size: 15px;
            text-align: center;
          }
        }
        .star-ratings {
          margin-left: 130px;
          position: relative;
          padding-top: 20px;

          .star-info {
            margin-top: -10px;
          }

          div {
            .rc-progress-line {
              width: 60%;
              margin-left: 10px;
              height: 3px;
              margin-bottom: 4px;
            }
          }
        }
      }
    }

    .comments {
      padding-right: 15px;
      padding-left: 15px;

      .scroller {
        overflow: scroll;
        white-space: nowrap;

        .comment {
          padding: 15px;
          border-radius: 14px;
          display: inline-table;
          background-color: #f2f2f8;
          @media (prefers-color-scheme: dark) {
            background-color: $dark-mode-comments-background-color;
          }
          margin-left: 15px;
          width: 280px; // TODO: find best value
          height: 214px;
          overflow: hidden;

          .header {
            display: flex;
            justify-content: space-between;

            .title {
              font-size: 14px;
              font-weight: bold;
              overflow: hidden;
              white-space: nowrap;
              max-width: 180px;
            }

            .date {
              font-size: 11px;
              color: $text-gray_color;
              @media (prefers-color-scheme: dark) {
                color: $dark-mode-text-gray-color;
              }
            }
          }

          .comment-rate {
            margin-top: 5px;
          }

          .text {
            width: 250px;
            white-space: pre-line;
            font-size: 15px;
            height: 100px; // TODO: fix long text issue and add more button
          }
        }
      }
    }

    .write-review {
      direction: ltr;
      margin-top: 20px;
      padding-right: 15px;
      padding-left: 15px;

      a {
        font-size: 17px;
        font-weight: bold;
        text-align: right;
        color: $color-primary;

        img {
          padding-left: 5px;
          margin-bottom: -6px;
        }
      }

      .separator {
        margin: 20px 0 0;
      }
    }

    .information {
      padding-right: 15px;
      padding-left: 15px;

      .info-container {
        h3 {
          font-size: 18px;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 25px;

          .title {
            font-size: 15px;
            color: $text-gray_color;
            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-gray-color;
            }
          }

          .value {
            font-size: 17px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .triad-section {
    overflow: hidden;

    .section-header {
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 16px;
        padding-right: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      a {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 16px;
        color: $color-primary;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .triad-swiper-container {
      width: 100%;
    }

    .swiper-wrapper {
      white-space: nowrap;
      display: flex;
      padding-bottom: 15px;
    }

    .app-group {
      display: flex;
      flex-direction: column;
      position: relative;
      width: -webkit-calc(100% - 45px);
      width:    -moz-calc(100% - 45px);
      width:         calc(100% - 45px);
      @media screen and (min-width: 500px) {
        width: calc(100vw / 3 - 45px);
      }
    }
  }


  .developer-apps-section {
    background-color: #F2F2F8;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-separator-color;
    }
  }

  .not-found-error {
    text-align: center;
    position: absolute;
    top: 120px;

    img {
      margin-right: auto;
      width: 100%;
    }

    p {
      text-align: center;
    }
  }

  .install-message {
    position: fixed;
    padding: 0 10px;
    margin-bottom: 30px;
    width: calc(100% - 20px);
    bottom: 0;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow_box {
      display: flex;
      padding: 10px;
      align-items: center;
    }

    .arrow_box {
      position: relative;
      background-color: $color-secondary;
      border: 4px solid #eee;
      @media (prefers-color-scheme: dark) {
        background-color: #464E54;
        border: 4px solid #464E54;
      }
      box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.24);
      border-radius: 5px;
      box-sizing: border-box;
      color: #333;
    }
    .arrow_box:after, .arrow_box:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .arrow_box:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #eee;
      @media (prefers-color-scheme: dark) {
        border-top-color: #464E54;
      }
      border-width: 10px;
      margin-left: -10px;
    }
    .arrow_box:before {
      border-color: rgba(238, 238, 238, 0);
      border-top-color: #eee;
      @media (prefers-color-scheme: dark) {
        border-top-color: #464E54;
      }
      border-width: 16px;
      margin-left: -16px;
    }

    .arrow_box > img {
      height: 50px;
    }

    .manual {
      img {
        height: 25px;
        padding: 0 7px;
      }
    }
  }
}