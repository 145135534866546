//$separators-color: #bec5d4;
$separators-color: #eeeeee;
$text-gray_color: #8e8e93;
$text-color: #111111;
$color-primary: #027aff;
$color-gray: #8e8e93;
$color-fields-background: #f8f9fc;
$color-secondary: #f2f2f8;
$color-red: #ff3a30;
$color-placeholder: #eeeeee;
$color-success: #3fc045;
$color-warning: #ff9800;

$dark-mode-background-color: #202435;
$dark-mode-text-color: #ffffff;
$dark-mode-separator-color: rgba(168, 180, 247, 0.12);
$dark-mode-tab-bar-color: #151823;
$dark-mode-text-gray-color: #7a7b7c;
$dark-mode-button-background-color: #1b1a21;
$dark-mode-comments-background-color: #1c1c1e;
