@import "~react-step-progress-bar/styles.css";

.device-registration-wizard {
  padding: 0px 2px;

  .device-registration-step {
    display: flex;
    flex-direction: column;

    height: 100%;
    img {
      width: 80%;
      display: block;
      margin-right: auto;
      margin-left: auto;
      border-radius: 26px;
    }

    .step {
      font-size: 16px;
      font-weight: 900;
      padding: 8px 0px;
    }
    .step-content {
      font-size: 14px;
      font-weight: bold;
      padding: 20px 8px;
      margin-right: 8px;
    }

    button {
      display: block;
      background: $color-primary;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      height: 56px;
      border: none;
      border-radius: 16px;
      color: #fff;
      font-size: 17px;
      font-weight: bold;
      text-align: center;
      bottom: 0;
      margin-top: auto;
    }

    button[disabled] {
      background-color: #8e8e93;
      color: #ffffff;
      /** TODO: Set dark mode color **/
    }

    .loading {
      margin-top: 40px;

      p {
        color: $color-gray;
        @media (prefers-color-scheme: dark) {
          color: $dark-mode-text-gray-color;
        }
        font-size: 14px;
        text-align: center;
      }
    }

    a.checkToken {
      text-align: center;
      color: #fff;
      background: $color-primary;
      padding: 10px 5px;
      border-radius: 10px;
      display: block;
      margin-top: 60px;
    }
  }

  .progtrckr {
    em {
      display: none;
    }

    span {
      display: inline-table;
    }
  }

  .RSPBprogressBar {
    margin-top: 50px;
  }

  .step-name {
    margin-top: -65px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;

    span {
      position: absolute;
      left: 0;
      top: -17px;
      width: 32px;
      height: 32px;
      text-align: center;
      border-radius: 16px;
      background: #fff;
      border: 2px solid $color-primary;

      @media (prefers-color-scheme: dark) {
        background-color: $dark-mode-background-color;
      }

      font-size: 16px;
      padding-top: 7px;
      font-weight: bold;
      box-sizing: border-box;
    }

    span.accomplished {
      color: #fff;
      background: $color-primary;
    }
  }

  .RSPBstep:first-child {
    margin-left: 35px;
  }

  .RSPBstep:nth-child(2) {
    .step-name {
      span {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .RSPBstep:nth-child(3) {
    padding-right: 65px;

    .step-name {
      span {
        left: 50%;
        transform: translateX(-100%);
      }
    }
  }

  .container {
    padding-top: 20px;
    height: 100%;
  }
}
