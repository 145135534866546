.container {
  background-color: #fff;
  @media (prefers-color-scheme: dark) {
    background-color: $dark-mode-background-color;
  }

  .header {
    position: fixed;
    width: 100%;
    height: 54px;
    background: #fff;
    z-index: 10;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    a {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    h1 {
      font-size: 18px;
      text-align: center;
      margin-top: 10px;
    }
  }
  .content {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 86vh;
    margin-top: 36px;
    h3 {
      margin-bottom: 8px;
    }
    p {
      font-size: 12px;
      margin-top: 1px;
      text-align: justify;
      padding-top: 8px;
      font-weight: 300;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex: 1;
      .phone-button {
        text-align: center;
        border-radius: 12px;
        background-color: transparent;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        p {
          font-size: 14px;
          color: $color-primary;
          margin-right: 8px;
          font-weight: 700;
        }
        min-width: 100%;
        padding: 8px;
        border: "2px solid";
        border-color: $color-primary;
      }
      .chat-button {
        text-align: center;
        border-radius: 12px;
        border: none;

        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        p {
          font-size: 14px;
          color: #fff;
          margin-right: 8px;
          font-weight: 700;
        }
        min-width: 100%;
        margin-bottom: 12px;
        margin-top: 12px;
        padding: 8px;
        background-color: $color-primary;
      }
      a {
        margin-top: 16px;
        color: $color-primary;
        font-size: 14px;
      }
    }
  }
}
