.submit-review {
  .header {
    position: fixed;
    width: 100%;
    height: 54px;
    background: #fff;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    a {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    h1 {
      font-size: 18px;
      text-align: center;
      margin-top: 10px;
    }
  }

  form {
    direction: ltr;
    text-align: center;
    padding-top: 80px;

    .widget-ratings {
      direction: ltr;
    }

    input, textarea {
      width: calc(100% - 30px);
      margin-top: 15px;
      border-radius: 5px;
      text-align: center;
      height: 46px;
      margin-right: 15px;
      margin-left: 15px;
      font-size: 17px;
      border: solid 1px $separators-color;
      background-color: $color-fields-background;

      @media (prefers-color-scheme: dark) {
        border-color: $dark-mode-separator-color;
        background-color: $dark-mode-separator-color;
      }
    }

    textarea {
      height: 150px;
      padding-top: 15px;
    }

    button, input[type=submit] {
      width: calc(100% - 30px);
      -webkit-appearance: none;
      height: 46px;
      margin: 20px 15px 15px;
      border-radius: 5px;
      border: none;
      background: $color-primary;
      color: #fff;
      text-align: center;
      font-size: 17px;
    }
  }
}