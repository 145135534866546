.device-registration {
  background: #f8f9fc;
  @media (prefers-color-scheme: dark) {
    background-color: $dark-mode-background-color;
  }
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;

  .back-button {
    position: fixed;
    left: 0;
    top: 0;
  }

  .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    h1, p {
      text-align: center;
      padding-left: 24px;
      padding-right: 24px;
    }

    h1 {
      margin-top: 48px;
      font-size: 22px;
    }

    p {
      margin-top: 5px;
      font-size: 14px;
    }

    button {
      height: 54px;
      background: $color-primary;
      border: none;
      border-radius: 10px;
      margin-left: 24px;
      margin-right: 24px;
      box-sizing: border-box;
      text-align: center;
      color: #fff;
      font-size: 17px;
      margin-top: 10px;
    }

    img {
      width: 100%;
    }

    a {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
      color: $color-primary;
    }
  }
}