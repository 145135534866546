@keyframes start-animation {
  from {
    // top: 100vh;
    opacity: 0;
  }
  to {
    // top: 50%;
    opacity: 1;
  }
}

.modal-with-gif-container {
  z-index: 2000;
  position: fixed;
  display: relative;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  background: rgba(0, 0, 0, 0.39);
  backdrop-filter: blur(8px);

  .modal-with-gif {
    position: absolute;
    width: 350px;
    height: 490px;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    background: #ffffff;
    border-radius: 20px;

    animation: start-animation 0.5s 1 alternate;
    animation-delay: 0s;
    animation-fill-mode: forwards;

    .gif-header,
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px;

      a {
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2px;

        background: #f5f5f5;
        border-radius: 50px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;

        color: #737373;
        font-weight: 800;
        font-size: 14pt;
      }
    }

    .content {
      .gif-container {
        margin-top: -70px;
      }

      .modal-body {
        padding: 8px;
      }
      .content-image {
        margin-top: 60px;
        max-width: 275px;
        height: auto;
      }

      .main-content {
        margin-top: -60px;
        padding: 0 38px;
        .badge {
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 17px;
          text-align: center;
          color: rgba(0, 0, 0, 0.38);
          flex: none;
          order: 0;
          flex-grow: 0;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 2px 8px;
          gap: 10px;

          width: 90px;
          height: 25px;

          background: #ffda79;
          border-radius: 8px;

          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 0;
        }

        h1 {
          color: $text-color;
          font-weight: 800;
          font-size: 20px;
          line-height: 35px;
          text-align: right;
        }

        p {
          color: $text-color;
          span {
            color: $text-color;
            font-weight: 800;
          }
        }

        label {
          color: $text-color;
        }

        .blue {
          background-color: #f3ec78;
          background-image: linear-gradient(0deg, #1d62f0, #1ad5fd);
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
        }

        .tip-container {
          display: flex;
          align-items: flex-end;
          margin-top: -13px;
          margin-bottom: 22px;
          .tip {
            color: #ff9800;
            font-size: 16px;
            font-weight: 700;
            // font-family: 'Peyda';
          }
        }

        .code-container {
          .code {
            background: #f5f5ff;
            border: 1px solid rgba(26, 29, 49, 0.12);
            border-radius: 12px 0px 12px 12px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-inline: 15px;
            margin-bottom: 15px;
            margin-top: 3px;
            div {
              color: rgba(0, 0, 0, 0.54);
            }
            img {
              height: 17px;
              width: 15px;
              padding-left: 5px;
            }
            .icon {
              font-size: 10px;
              display: flex;
              align-items: center;
            }
          }
        }

        button {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8.31162px 10.8051px 8.31162px 13.2986px;
          gap: 4.99px;
          margin-top: 100px;

          width: 274px;
          height: 42px;

          background: rgba(24, 107, 231, 0.9);
          border: 0.831162px solid rgba(120, 180, 255, 0.5);
          box-shadow: 0px 4.98697px 35.7399px rgba(128, 146, 255, 0.6);
          border-radius: 9.97394px;
          color: "white";

          flex: none;
          order: 1;
          flex-grow: 0;

          font-weight: 700;
          font-size: 12pt;
          line-height: 20px;
        }
      }
    }
  }
}
