.not-found {
  position: relative;
  height: 85vh;

  .background {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -20%);
    width: 100%;
  }

  .content {
    position: absolute;
    bottom: 0;
    height: 50%;
    padding: 15px;

    p, h3 {
      text-align: center;
    }

    h3 {
      margin-bottom: 15px;
    }

    .actions {
      margin-top: 15px;

      a {
        display: block;
        text-align: center;
        color: $color-primary;
        font-size: 17px;
        font-weight: bold;
      }

      .support {
        display: block;
        text-align: center;
        margin-top: 15px;
        font-weight: normal;
        font-size: 14px;
        color: #000;
        @media (prefers-color-scheme: dark) {
          color: #fff;
        }
      }
    }
  }
}