.dialog-container {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  @media (prefers-color-scheme: dark) {
    background: rgba(255, 255, 255, 0.4);
  }

  .dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 48px);
    background: #fff;
    overflow: hidden;
    @media (prefers-color-scheme: dark) {
      background: $dark-mode-background-color;
    }
    border-radius: 15px;

    .content {
      padding: 24px;
      box-sizing: border-box;

      h3, p {
        text-align: center;
      }
      h3 {
        margin-bottom: 5px;
      }
    }

    .actions {
      button {
        width: 50%;
        background: none;
        border: 1px solid $separators-color;
        @media (prefers-color-scheme: dark) {
          border-color: $dark-mode-separator-color;
        }
        border-bottom: none;
        text-align: center;
        padding: 15px 0;
        font-size: 16px;
      }

      .negative {
        border-right: none;
        font-weight: normal;
      }

      .positive {
        font-weight: bold;
      }
    }
  }
}