.purchase-package-action {
  position: fixed;
  bottom: 56px;
  background-color: $color-primary;
  width: 100vw;
  height: 35px;
  text-align: center;
  padding-top: 12px;
  color: #fff;
  font-weight: bold;
}

.iphone-x {
  @media screen and (orientation: portrait) {
    .purchase-package-action {
      bottom: calc(56px + 34px);
    }
  }
}
