.payment-options-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  @media (prefers-color-scheme: dark) {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .payment-options {
    z-index: 200;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 425px;
    width: 100%;
    background-color: #fff;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: 30px 0px 0px;
    box-sizing: border-box;

    .alerts {
      /* @media screen and (max-height: 667px) {
        margin-top: 32px;
      } */

      padding-left: 15px;
      padding-right: 15px;

      p {
        font-family: iranyekan;
        padding-top: 2px;
        font-size: 12px;
        color: $text-gray_color;
      }

      b {
        font-size: 14px;
        color: $text-gray_color;
      }

      margin-bottom: 8px;

      @media screen and (device-aspect-ratio: 40/71) {
        .alerts-title {
          display: none;
        }
      }
    }

    .discount {
      position: relative;
      background-color: $color-fields-background;

      @media screen and (min-height: 667px) {
        margin-top: 10px;
      }

      @media (prefers-color-scheme: dark) {
        background-color: $dark-mode-separator-color;
      }

      padding: 15px 15px 15px;

      @media screen and (device-aspect-ratio: 40/71) {
        padding: 5px 5px 5px;
      }

      @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
        // iPhone 6/7/6s
        padding: 10px 10px 10px;
      }

      a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .image {
          width: 46px;
          height: 34px;
          background-color: rgb(190, 197, 212);
          display: flex;
          justify-items: center;
          border-radius: 5px;
          margin-top: auto;
          margin-bottom: auto;

          img {
            margin: auto;
            width: 16px;
            height: 16px;
          }
        }

        .description {
          .title {
            font-size: 17px;
            font-weight: bold;
          }

          .subtitle {
            font-size: 14px;
            color: $text-gray_color;

            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-gray-color;
            }
          }
        }
      }
    }

    .discount-field {
      .title {
        font-size: 17px;
        font-weight: bold;

        @media screen and (device-aspect-ratio: 40/71) {
          font-size: 12px;
        }

        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
          // iPhone 6/7/6s
          font-size: 14px;
        }
      }

      form {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        input[type="text"] {
          flex: 1;
          border: 1px solid rgb(190, 197, 212);
          color: #000;

          @media (prefers-color-scheme: dark) {
            background-color: #000000;
            border-color: $dark-mode-separator-color;
            color: $dark-mode-text-color;
          }

          margin-left: 5px;
          border-radius: 5px;
          text-align: center;
          font-size: 17px;
          letter-spacing: 2px;
        }

        button[type="submit"] {
          width: 75px;
          background-color: $color-gray;
          border: none;
          height: 56px;

          @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
            // iPhone 6/7/6s
            height: 40px;
          }

          @media screen and (device-aspect-ratio: 40/71) {
            height: 40px;
          }

          text-align: center;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          border-radius: 5px;
        }

        button.active {
          background-color: $color-primary;
        }
      }

      .success {
        background-color: rgba(2, 122, 255, 0.06);
        border-radius: 5px;
        display: flex;
        padding: 16px;
        margin-top: 5px;

        p {
          margin: auto;
          text-align: center;
          font-size: 17px;
          font-weight: bold;
          color: $color-primary;

          @media screen and (device-aspect-ratio: 40/71) {
            font-size: 12px;
          }

          @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
            // iPhone 6/7/6s
            font-size: 14px;
          }
        }
      }
    }

    .discount.error {
      .discount-field {
        .title {
          color: $color-red;
          font-size: 14px;
        }
      }
    }

    .progress {
      padding-top: 45px;

      .values {
        display: flex;
        justify-content: space-between;

        .final {
          font-size: 14px;
          color: $color-primary;
        }

        .total {
          font-size: 14px;
          color: $text-gray_color;
        }
      }
    }

    .actions {
      .button {
        margin-top: 15px;
        background-color: $color-primary;
        color: #fff;
        width: 100%;
        height: 56px;
        border: none;
        text-align: center;
        border-radius: 10px;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }

  .payment-options.show-discount {
    height: 460px;
  }

  .packages-list {
    .subscription-package {
      .content {
        margin-bottom: 0;
        padding: 0px 15px;
      }
    }

    /* @media screen and (max-height: 667px) {
       display: none;
    } */
  }
}

.gateway-redirection {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;

  @media (prefers-color-scheme: dark) {
    background-color: $dark-mode-background-color;
  }

  z-index: 250;
}

.v2 {
  .payment-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    top: 0;
    border-radius: 0;
    padding-top: 5px;
    background-color: #ffffff;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    .back-button-wrapper {
      text-align: left;
      direction: ltr;
    }

    .package-preview {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      border-radius: 10px;
      margin: 40px 15px 5px;

      .content {
        background-color: unset;
        border-radius: 10px;
        height: 148px;
        margin-bottom: 5px;

        @media screen and (device-aspect-ratio: 40/71) {
          height: 90px;
        }

        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
          // iPhone 6/7/6s
          height: 130px;
        }

        .basic-info {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .description {
          font-size: 10px;
          margin-top: 10px;
          text-align: right;
        }

        h2 {
          padding: 0;
          margin: 0;
          font-size: 18px;
          font-family: iranyekan, sans-serif;
        }

        p {
          font-size: 16px;
          padding: 0;
          margin: 0;
          font-family: iranyekan, sans-serif;

          span {
            color: #fff;
            font-size: 11px;
          }
        }
      }
    }
  }

  .payment-options.show-discount {
    height: 100%;
  }

  .payment-options-details {
    padding-bottom: 150px;
    overflow-y: auto;
  }

  .actions {
    position: fixed;
    bottom: 0;
    background: #fff;

    @media (prefers-color-scheme: dark) {
      background: $dark-mode-background-color;
    }

    width: 100%;
    box-sizing: border-box;
    padding: 15px;

    .prices {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .key {
        font-size: 13px;
        margin: 5px;
        color: $text-gray_color;

        @media (prefers-color-scheme: dark) {
          color: $dark-mode-text-gray-color;
        }

        font-family: iranyekan,
        sans-serif;
      }

      .value {
        font-size: 17px;
        font-weight: bold;
        font-family: iranyekan, sans-serif;
      }

      .value.normal-weight {
        font-size: 13px;
        font-weight: normal;
      }
    }

    .prices-divider {
      border-bottom: 1px solid $separators-color;
      margin-bottom: 10px;
    }

    // iphone se
    @media screen and (max-height: 667px) {
      padding: 8px 15px;

      .prices-divider {
        margin-bottom: 2px;
      }

      .button {
        max-height: 35px;
      }
    }
  }

  .gateway-list {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: streach;
    gap: 8px;

    @media screen and (max-height: 667px) {
      margin-top: 16px;
    }

    h4 {
      margin: 0 8px;
      font-weight: bold;
      font-size: 14px;
    }

    button.active {
      border: 2px solid $color-primary;
    }

    button {
      background: #fff;
      margin: 0 8px;
      padding: 4px;

      @media screen and (min-height: 667px) {
        padding: 2px 4px;
      }

      border: 1px solid $separators-color;
      border-radius: 8px;

      @media (prefers-color-scheme: dark) {
        background: $dark-mode-background-color;
        border: 1px solid $dark-mode-separator-color;
      }

      .gateway {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        img {
          width: 60px;
          height: 60px;

          @media screen and (max-height: 667px) {
            width: 40px;
            height: 40px;
          }

          padding: 8px;
          box-sizing: border-box;
          object-fit: contain;
        }

        .gateway-info {
          h6 {
            font-size: 16px;
            font-weight: bold;
          }

          p {
            color: $text-gray_color;

            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-color;
            }
          }
        }
      }
    }
  }
}
