.downloader-container {
  .app-downloader {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 550px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px #666;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
      box-shadow: 1px 1px 5px $dark-mode-separator-color;
    }


    .actions {
      display: flex;
      justify-content: space-between;

      a.cancel-button {
        color: $color-primary;
      }
    }

    .separator {
      margin: 15px 0 0;
    }

    .distribution-type {
      padding-top: 15px;

      .separator {
        margin-bottom: 15px;
      }

      .expandable {
        display: flex;
        justify-content: space-between;

        .info {
          display: flex;
          justify-content: right;

          .icon {
            width: 46px;
            height: 46px;
            border-radius: 8px;
            background-color: $color-primary;
            display: flex;
            justify-content: center;


            img {
              margin-top: auto;
              margin-bottom: auto;
            }
          }

          .description {
            margin-right: 15px;
            margin-top: auto;
            margin-bottom: auto;

            h1 {
              font-size: 14px;
              font-weight: bold;
            }

            p {
              font-size: 11px;
              color: $text-gray_color;
              @media (prefers-color-scheme: dark) {
                color: $dark-mode-text-gray-color;
              }
            }
          }

          .download-button {
            align-self: center;
            margin-left: auto;
          }
        }
        .download-button {
          background: none;
          border: none;
        }
      }
      .expandable-content {
        display: none;

        .content {

          a {
            font-size: 14px;
            margin-top: 15px;
            color: $color-primary;
          }
          p {
            font-size: 13px;
            margin-top: 10px;
          }

          .info {
            display: flex;
            justify-content: space-between;

            margin-top: 20px;

            .key {
              color: $text-gray_color;
              @media (prefers-color-scheme: dark) {
                color: $dark-mode-text-gray-color;
              }
              font-size: 13px;
            }
            .value {
              font-size: 14px;
              font-weight: bold;
            }
          }
        }
      }
      .expandable-content.expanded {
        display: unset;
      }
    }

    .distribution-type.disabled {
      filter: invert(57%) sepia(0%) saturate(2%) hue-rotate(152deg) brightness(94%) contrast(90%);
    }

    .all-options {
      color: $color-primary;
      display: block;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}