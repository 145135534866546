.full-modal-container {
	.full-modal {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		overflow: hidden;
		background: rgba(0, 0, 0, 0.06);
		backdrop-filter: blur(8px);
		z-index: 1000;


		.custom-modal {
			width: 300px;
			height: 335px;
			position: absolute;
			left: 50%;
			top: 50vh;
			transform: translate(-50%, -50%);
			background-color: #fff;
			background-image: url(/images/update-modal-header.jpg);
			background-size: 436px 148px;
			background-repeat: no-repeat;
			background-position: -100px -25px;
			box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			pointer-event: none;
		}
	}

	.modal-image {
		img {
			z-index: 1001;
			position: absolute;
			top: -125px;
			right: 10px;
		}
	}

	.modal-header {
		// margin-top: 110px;
		h3 {
			text-align: center;
			color: #1A93D8;
			font-weight: 800;
		}

		a {
			position: absolute;
			color: gray;
			top: 5px;
			left: 10px;
			font-size: 10pt;
			font-weight: 800;
		}

		.modal-logo {
			margin-top: 25px;
			margin-right: 20px;
		}

		.modal-featured-image {
			margin-top: 15px;

			.texts {
				display: flex;
				justify-content: center;
				h5 {
					color: #4B4D4D;
					font-size: 14px;
					font-weight: 700;
					strong {
						color: #4B4D4D;
						font-weight: 700;
						font-size: 22px;
					}
				}

				h5:first-child {
					margin-left: 43px;
				}
			}
		}
	}

	.modal-body {
		text-align: center;

		p {
			padding: 24px;
			text-align: center;
			color: $text-gray_color;
			font-weight: 400;

			strong {
				color: $text-gray_color;
				font-weight: 800;
			}

			margin-bottom: 15px;
		}

		.modal-primary-button {
			text-align: center;
			background-color: $color-red;
			color: #fff;
			max-height: 44px;
			padding: 8px;
			border-radius: 20px;
		}

		.support-link {
			color: $text-gray_color;
			font-size: 14px;
		}
	}
}