.report-account-problem {
  padding: 15px;

  img {
    width: 100%;
  }

  h1 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
  }

  p {
    text-align: right;
    font-size: 14px;
  }

  a {
    display: block;
    text-align: center;
    margin-top: 25px;
    background-color: $color-primary;
    height: 44px;
    border-radius: 22px;
    color: #fff;
    padding-top: 10px;
    box-sizing: border-box;
  }

  input[type=phone] {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid $separators-color;
    text-align: center;
  }

  form {
    padding-top: 15px;

    label {
      color: $color-red;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}