@import '~react-toastify/dist/ReactToastify.css';

.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: rgba(76, 217, 100, 0.96) !important;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: rgba(255, 58, 48, 0.96) !important;;
}