@keyframes start-animation { 
    from {
      transform: translate(100%, 100%) rotate(45deg);
		opacity: 0;
    }
    to {
      transform: translate(0, 0) rotate(0deg);
		opacity: 1;
    }
  }

.ig-live-campaign-modal-container {
	z-index: 2000;
	position: fixed;
	display: relative;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;

	h1, p, a {
		color: $dark-mode-text-color;
	}

	background: rgba(0, 0, 0, 0.39);
	backdrop-filter: blur(8px);
	text-align: left;

	.animation {
		animation: start-animation 1s 1 alternate;
		animation-delay: 0s;
		animation-fill-mode: forwards;
		text-align: left;
		direction: ltr;

		.full-image {
			// display: none;
			position: absolute;
			z-index: -1;
			left: -387px;
			top: -59px;
		}
	}

	img.background {
		// display: none;
		margin-top: -60px;
	}

	.phone-content {
		position: absolute;
		top: 105px;
		left: 50px;
		padding: 0 35px;
		height: 550px;


		.header {
			display: flex;
			justify-content: space-between;
			width: 220px;
			align-items: center;
		}

		.content {
			text-align: right;
			position: absolute;
			bottom: 0;
			margin-bottom: 40px;
			width: 220px;


			h1 {
				font-size: 16pt;
				font-weight: 800;
			}

			p {
				font-size: 12px;
				margin-bottom: 61px;
			}

			a {
				box-sizing: border-box;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 8.31162px 10.8051px 8.31162px 13.2986px;
				gap: 4.99px;

				position: absolute;
				width: 206.96px;
				height: 36.62px;
				bottom: 0;

				background: rgba(24, 107, 231, 0.9);
				border: 0.831162px solid rgba(120, 180, 255, 0.5);
				box-shadow: 0px 4.98697px 35.7399px rgba(128, 146, 255, 0.6);
				border-radius: 9.97394px;
			}

			.separator {
				width: 39.9px;
				height: 3.32px;
				margin-right: 0px;
				margin-bottom: 10px;
				background: linear-gradient(270deg, #1964D7 -0.67%, #2CD095 -0.66%, #FFFFFF 51.43%, #EE2742 102.45%);
				box-shadow: 16.6232px 3.32465px 8.31162px 0.831162px rgba(44, 208, 149, 0.4), 0px 1.66232px 8.31162px 0.831162px rgba(255, 255, 255, 0.4), -11.6363px 1.66232px 8.31162px 0.831162px rgba(255, 96, 96, 0.4);
				border-radius: 2.49348px;
				flex: none;
				order: 1;
				flex-grow: 0;
			}
		}
	}

	#close-button {
		background-color: gray;
		position: absolute;
		padding: 2px 5px;
		font-size: 10pt;
		font-weight: 600;
		top: -35px;
		right: 12px;
		text-align: center;
		border-radius: 10px;
	}
}