.subscription-packages {
  .header {
    position: fixed;
    width: 100%;
    height: 54px;
    background: #fff;
    z-index: 10;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    a {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    h1 {
      font-size: 18px;
      text-align: center;
      margin-top: 10px;
    }
  }

  .packages-header {
    padding: 80px 15px 30px;

    h1 {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      color: $text-gray_color;

      @media (prefers-color-scheme: dark) {
        color: $dark-mode-text-gray-color;
      }

      font-size: 15px;
    }
  }

  .has-survey {
    padding-top: 130px;
  }
}

.subscription-package,
.package-preview {
  margin: 15px;

  .content {
    background-color: $color-placeholder;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-separator-color;
    }

    box-sizing: border-box;
    height: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-size: 130%;
    background-position: center;
    border-radius: 15px;
    margin: 15px 0;
    padding: 8px 15px;

    h2 {
      color: #fff;
      text-align: center;
      font-size: 20px;
      padding: 30px 0 20px;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 16px;
      padding: 0 0 20px;
    }

    p.hasVoucher {
      text-decoration: line-through;
      padding-bottom: 10px;
      color: $text-gray_color;
    }

    p.voucherPrice {
      padding: 0;
      font-weight: bold;
    }
  }
}

.survey-message {
  position: fixed;
  z-index: 5;
  padding: 59px 5px 5px;
  background-color: #51d869;
  font-size: 14px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  a {
    color: #fff;
  }

  b {
    color: #fff;
    font-weight: bold;
    font-size: 22px;
  }
}

.package-0 {}

.package-1 {}

.package-2 {}

.package-preview.package-2 {
  background-image: url("/images/large-blue-background.png");
}

.package-preview.package-1 {
  background-image: url("/images/large-dark-blue-background.png");
}

.package-preview.package-0 {
  background-image: url("/images/large-green-background.png");
}

.package-preview.package-3 {
  background-image: url("/images/large-purple-background.png");
}

.package-preview.package-4 {
  background-image: url("/images/large-red-background.png");
}
