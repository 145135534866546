.search {
  position: relative;
  padding-bottom: 60px;
  min-height: 85vh;

  h1 {
    font-size: 30px;
    margin-top: 20px;
    margin-right: 15px;
  }

  .scroller {
    padding-right: 15px;
    padding-bottom: 15px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    input[type=text] {
      margin-right: 15px;
      margin-left: 15px;
      margin-top: 6px;
      border-radius: 8px;
      height: 46px;
      background-color: #f2f2f8;
      color: #000;
      @media (prefers-color-scheme: dark) {
        color: $dark-mode-text-color;
        background-color: $dark-mode-separator-color;
      }
      border: none;
      padding-right: 15px;
      font-size: 16px;
    }

    button.search-button {
      position: absolute;
      left: 25px;
      top: 55%;
      transform: translateY(-50%);
      background-color: transparent;
      border: none;
      width: 20px;
      height: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  h2 {
    font-size: 22px;
    margin-top: 20px;
    margin-right: 15px;
  }

  .trends {
    padding-top: 110px;
  }

  .search-trend {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    font-size: 17px;
    color: $color-primary;
    margin-right: 15px;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $separators-color;
    @media (prefers-color-scheme: dark) {
      border-bottom-color: $dark-mode-separator-color;
    }
    margin-left: 15px;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    @media (prefers-color-scheme: dark) {
      background: $dark-mode-background-color;
    }
    z-index: 100;
    padding-bottom: 5px;
  }

  .not-found-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}