.terms {

	.term-items {
		padding: 16px;

		p {
			margin-bottom: 16px;
			text-align: justify;
		}
	}

	h1 {
		padding-top: 64px;
		padding-right: 16px;
		padding-left: 16px;
		font-size: 16pt;
		color: $color-primary;
	}

	h4, h5 {
		color: $color-primary;
	}

	strong {
		font-style: bold;
	}
}
