.featured-apps {
  padding-top: 100px; // TODO: Find best value
  padding-bottom: 60px; // TODO: Find best value

  .header {
    position: fixed;
    background-color: white;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 28px;
    z-index: 200;

    h1 {
      font-size: 30px;
      padding-right: 15px;
      transition: font-size 200ms;
    }
  }

  .header.small {
    h1 {
      font-size: 20px;
    }
  }

  .banner-section {
    .scroller {
      overflow-x: scroll;
      white-space: nowrap;
      padding-bottom: 20px;
      width: 100%;

      a:last-child {
      }

      .swiper-slide {
        position: relative;
        display: inline-table;
        width: -webkit-calc(100% - 45px);
        width: -moz-calc(100% - 45px);
        width: calc(100% - 45px);
        @media screen and (min-width: 500px) {
          max-width: 500px;
        }
      }

      .banner {
        display: inline-table;
        width: 100%;

        .label {
          font-size: 11px;
          font-weight: bold;
          color: $color-primary;
        }

        .title {
          font-size: 22px;
          max-width: 90vw;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .subtitle {
          font-size: 17px;
          margin-bottom: 5px;
          color: $text-gray_color;
          @media (prefers-color-scheme: dark) {
            color: $dark-mode-text-gray-color;
          }
        }

        img {
          //width: 336px; // TODO: find best value
          width: 100%; // TODO: find best value
          height: -webkit-calc((100vw - 45px) / 1.3);
          height: -moz-calc((100vw - 45px) / 1.3);
          height: calc((100vw - 45px) / 1.3);
          min-height: calc((100vw - 45px) / 1.3);
          margin-top: 5px;
          border-radius: 14px;
          background-color: $color-placeholder;
          border: 1px solid $separators-color;
          @media (prefers-color-scheme: dark) {
            background-color: $dark-mode-separator-color;
            border-color: $dark-mode-separator-color;
          }
          @media screen and (min-width: 500px) {
            min-height: calc((100vw / 3 - 45px) / 1.3);
            height: auto;
          }
        }
      }
    }
  }

  .single-app-section {
    .section-header {
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 20px;
        padding-right: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      a {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 16px;
        color: $color-primary;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .scroller {
      padding-right: 15px;
      white-space: nowrap;
      overflow-y: hidden;
      display: flex;
      padding-bottom: 15px;

      .single-app:last-child {
        padding-left: 15px;
      }
      .single-app {
        width: 160px;
        margin-left: 15px;

        img {
          width: 160px;
          height: 160px;
          border-radius: 36px;
          border: 1px solid $separators-color;
          background-color: $color-placeholder;
          @media (prefers-color-scheme: dark) {
            border-color: $dark-mode-separator-color;
            background-color: $dark-mode-separator-color;
          }
        }

        .info {
          margin-top: 10px;

          .title {
            width: 160px;
            font-size: 17px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .category {
            font-size: 14px;
            color: $text-gray_color;
            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-gray-color;
            }
          }
        }
      }
    }
  }

  .triad-section {
    overflow: hidden;

    .section-header {
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 20px;
        padding-right: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      a {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 16px;
        color: $color-primary;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .triad-swiper-container {
      width: 100%;
    }

    .swiper-wrapper {
      white-space: nowrap;
      display: flex;
      padding-bottom: 15px;
    }

    .app-group {
      display: flex;
      flex-direction: column;
      position: relative;
      width: -webkit-calc(100% - 45px);
      width: -moz-calc(100% - 45px);
      width: calc(100% - 45px);
      @media screen and (min-width: 500px) {
        width: calc(100vw / 3 - 45px);
      }
    }
  }

  .app-with-secreenshot-section {
    overflow: hidden;

    .section-header {
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 20px;
        padding-right: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      a {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 16px;
        color: $color-primary;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .scroller {
      .swiper-wrapper {
        white-space: nowrap;
        display: flex;
        padding-bottom: 15px;
      }
    }
  }

  .categories-section {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 20px;

    .section-header {
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      a {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 16px;
        color: $color-primary;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}

.category-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-left: 15px;
  }

  p {
    padding: 13px 0;
    flex: 1;
    font-size: 17px;
  }
}

.app-with-screenshot {
  display: flex;
  flex-direction: column;
  width: -webkit-calc(100vw - 45px);
  width: -moz-calc(100vw - 45px);
  width: calc(100vw - 45px);
  @media screen and (min-width: 500px) {
    width: 350px;
  }

  .triad-app {
    margin-left: auto;
  }

  .screenshots {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

    div {
      background-size: 120%;
      background-position: center;
      background-color: $color-placeholder;
      @media (prefers-color-scheme: dark) {
        background-color: $dark-mode-separator-color;
      }
      width: 106px;
      height: 188px;
      border-radius: 8px;
      align-self: center;
    }
  }
}
.app-with-screenshot:last-child {
  padding-left: 15px;
}

.triad-app {
  margin-top: 10px;
  margin-bottom: 0;

  width: -webkit-calc(100vw - 45px);
  width: -moz-calc(100vw - 45px);
  width: calc(100vw - 45px);
  @media screen and (min-width: 500px) {
    width: calc(100vw / 3 - 45px);
  }

  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  align-self: center;

  a {
    @media screen and (min-width: 500px) {
      width: calc(100vw / 3 - 110px);
    }
    display: flex;
    flex: 1;
  }

  img {
    width: 66px;
    height: 66px;
    border-radius: 14px;
    margin-top: 7px;
    border: 1px solid $separators-color;
    background-color: $color-placeholder;
    @media (prefers-color-scheme: dark) {
      border-color: $dark-mode-separator-color;
      background-color: $dark-mode-separator-color;
    }
  }

  .icon-wrapper {
    position: relative;

    .icon-badge {
      position: absolute;
      width: 20px;
      height: 20px;
      border: none;
      left: -10px;
      top: -10px;
    }
  }

  button {
    margin-top: 0;
  }

  .info {
    margin-right: 10px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    @media screen and (min-width: 500px) {
      max-width: calc(100vw / 3 - 110px);
    }
    // border-bottom: solid 1px #bec5d4;

    .title {
      font-size: 15px;
      width: calc(100vw - 200px);
      @media screen and (min-width: 500px) {
        width: calc(100vw / 3 - 150px);
      }
      overflow: hidden;
      white-space: pre-line;
      max-height: 50px;
      text-overflow: ellipsis;
    }

    .category {
      font-size: 14px;
      color: $text-gray_color;
      @media (prefers-color-scheme: dark) {
        color: $dark-mode-text-gray-color;
      }
    }
  }

  button {
    align-self: flex-start;
  }
}

.developer-app {
  .download-button {
    button {
      background-color: #fff;
    }
  }
}

.iphone-x {
  .featured-apps {
    //padding-bottom: 100px;
  }
}
