@import "~react-step-progress-bar/styles.css";

.packages-container {
  padding: 24px 0px;

  .header {
    position: fixed;
    width: 100%;
    height: 54px;
    background: #fff;
    z-index: 10;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    a {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    h1 {
      font-size: 18px;
      text-align: center;
      margin-top: 10px;
    }
  }
}

.package-layout {
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 16px;

  .register-segue {
    display: flex;
    margin-top: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;

    .register-device-segue__title {
      font-size: 16px;
      text-align: center;
      font-weight: 900;
    }

    .register-device-segue__description {
      font-size: 14px;
      text-align: center;
      text-justify: initial;
      padding: 4px 8px;
      font-weight: 300;
    }

    button {
      display: block;
      background: $color-primary;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      height: 46px;
      margin-top: 10px;
      border: none;
      border-radius: 10px;
      color: #000;
      font-size: 17px;
      font-weight: bold;
      text-align: center;
    }

    .register-segue__loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 50%;

      button {
        display: block;
        background: transparent;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        height: 46px;
        margin-top: 10px;
        border: none;
        color: $color-primary;
        border-radius: 10px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .subscription-steps {
    margin-top: 18px;

    img {
      width: 80%;
      display: block;
      margin-right: auto;
      margin-left: auto;
    }

    p {
      font-size: 14px;
      margin-bottom: 5px;

      span {
        font-size: 22px;
        color: $color-primary;
        font-weight: bold;
      }
    }

    button {
      display: block;
      background: $color-primary;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      height: 46px;
      margin-top: 10px;
      border: none;
      border-radius: 10px;
      color: #000;
      font-size: 17px;
      font-weight: bold;
      text-align: center;
    }

    .loading {
      margin-top: 40px;

      p {
        color: $color-gray;

        @media (prefers-color-scheme: dark) {
          color: $dark-mode-text-gray-color;
        }

        font-size: 14px;
        text-align: center;
      }
    }

    a.checkToken {
      text-align: center;
      color: #000;
      background: $color-primary;
      padding: 10px 5px;
      border-radius: 5px;
      display: block;
      margin-top: 60px;
    }
  }

  .progtrckr {
    em {
      display: none;
    }

    span {
      display: inline-table;
    }
  }

  .RSPBprogressBar {
    margin-top: 50px;
  }

  .step-name {
    margin-top: -65px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;

    span {
      position: absolute;
      left: 0;
      top: -17px;
      width: 32px;
      height: 32px;
      text-align: center;
      border-radius: 16px;
      background: #fff;
      border: 2px solid $color-primary;

      @media (prefers-color-scheme: dark) {
        background-color: $dark-mode-background-color;
      }

      font-size: 16px;
      padding-top: 7px;
      font-weight: bold;
      box-sizing: border-box;
    }

    span.accomplished {
      color: #000;
      background: $color-primary;
    }
  }

  .RSPBstep:first-child {
    margin-left: 30px;
  }

  .RSPBstep:nth-child(2) {
    .step-name {
      span {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .RSPBstep:nth-child(3) {
    padding-right: 65px;

    .step-name {
      span {
        left: 50%;
        transform: translateX(-100%);
      }
    }
  }
}

.subscription-packages.v2 {
  padding: 10px 0;

  .button {
    background: $color-primary;
    width: 100%;
    height: 44px;
    margin-top: 20px;
    border: none;
    border-radius: 15px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }

  .general-message {
    font-size: 10px;
    color: $text-gray_color;
    margin-right: 10px;
    margin-top: 10px;
  }

  .header {
    text-align: right;
    direction: rtl;
    position: relative;
    height: auto;

    h1 {
      text-align: right;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      padding: 0 15px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 12px 0 0;

      li {
        display: flex;
        border-bottom: 1px solid $color-secondary;

        @media (prefers-color-scheme: dark) {
          border-bottom: 1px solid $dark-mode-separator-color;
        }

        padding: 12px 15px;

        .feature-icon {
          width: 40px;
          height: 40px;
          border-radius: 5px;
          //background-image: linear-gradient(222deg, #00cbff 76%, #0064f9 -4%);
          background: linear-gradient(222deg,
              rgba(0, 203, 255, 1) 0%,
              rgba(0, 100, 249, 1) 100%);

          img {
            width: 20px;
            height: 20px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
          }
        }

        .feature-description {
          margin-right: 10px;

          h2 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;
            color: $text-gray_color;

            @media (prefers-color-scheme: dark) {
              color: $dark-mode-text-gray-color;
            }
          }
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }
  }

  .payment-options-container {
    z-index: 100;
  }

  .header.back-button {
    position: absolute;
    top: 20px;
    left: 0;

    .back-button {
      width: 30px;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
  }
}

.packages-list {
  margin-top: 18px;
  padding: 8px;

  h1 {
    font-size: 18px;
    font-weight: bold;
  }

  .subscription-package {
    margin: 0;

    .package {
      position: relative;
      overflow: hidden;
      border-radius: 24px;

      .package-header-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .package-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          .detail {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            gap: 10px;
            width: 100%;

            h3 {
              color: #000;
              padding: 0;
              font-weight: 700;
              font-size: 14px;
            }

            .description {
              opacity: 0.8;
              font-size: 12px;
              font-weight: 300;
              color: #000;
              text-align: right;
              word-wrap: break-word;
              max-width: 80%;
            }

            .reward {
              display: flex;
              flex-direction: row;
              align-items: center;

              img {
                overflow: hidden;
                width: 32px;
                height: 32px;
                margin-left: 8px;
              }

              .title {
                font-size: 12px;
                padding: 0;
                color: #000;
              }
            }
          }

          .price-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;
          }

          .price-title>h3 {
            font-size: 22px;
            font-weight: bold;
          }

          .originalPrice {
            font-weight: normal;
            // text-decoration: line-through;
            font-size: 10px;
            padding: 0;
            color: #fff;
            background: $color-primary;
            align-self: end;
            // opacity: 0.5;
            padding: 5px;
            border-radius: 15px;
          }

          .prices {
            display: flex;
            flex-direction: column;
            align-items: start;
            align-self: center;

            .price {
              font-size: 28px;
              color: #000;
              padding: 0;
              font-weight: 800;
            }

            .price>span {
              font-size: 18px;
            }

            .currency {
              opacity: 0.7;
              color: #000;
              padding: 0;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
