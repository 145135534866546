.app-listing {
  .header {
    position: fixed;
    width: 100%;
    height: 54px;
    background: #fff;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    a {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    h1 {
      font-size: 18px;
      text-align: center;
      margin-top: 10px;
    }
  }

  .items {
    padding: 70px 15px 15px;
  }

  .app-listing-item {
    display: flex;
    margin-bottom: 24px;

    img {
      display: block;
      max-width: 86px;
      max-height: 86px;
      min-width: 86px;
      min-height: 86px;
      width: auto;
      height: auto;
      border: 1px solid $separators-color;
      @media (prefers-color-scheme: dark) {
        border-color: $dark-mode-separator-color;
      }
      border-radius: 18px;
      margin-left: 15px;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      .title {
        font-size: 17px;
        white-space: nowrap;
        width: 100%;
      }

      .category {
        font-size: 14px;
        color: $text-gray_color;
        @media (prefers-color-scheme: dark) {
          color: $dark-mode-text-gray-color;
        }
        margin-bottom: 9px;
      }
    }
  }
}

.category-listing {
  .items {
    padding-top: 60px;
  }
  .category-item {
    margin-right: 15px;
  }
}