.request-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  @media (prefers-color-scheme: dark) {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .app-request-form {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: 15px;

    .form-group {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 5px;
        font-size: 14px;
      }

      input[type=text], textarea {
        border: 1px solid rgb(190, 197, 212);
        color: #000;
        @media (prefers-color-scheme: dark) {
          background-color: $dark-mode-separator-color;
          border-color: $dark-mode-separator-color;
          color: $dark-mode-text-color;
        }
        border-radius: 5px;
        text-align: center;
        height: 44px;
      }

      textarea {
        padding: 5px;
        height: 100px;
      }
    }

    .button {
      background: $color-primary;
      width: 100%;
      height: 44px;
      margin-top: 20px;
      border: none;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 70px;

      color: #fff;
    }
  }
}