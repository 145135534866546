//@import "~bootstrap/dist/css/bootstrap.min.css";

* {
  padding: 0;
  margin: 0;
  direction: rtl;
  text-align: right;
}

@import "variables";
@import "typography";

@import "containers/featured-apps";
@import "containers/search";
@import "containers/login";
@import "containers/profile";
@import "containers/app-detail";
@import "containers/app-downloader";
@import "containers/subscription-packages";
@import "containers/subscription-packages-v2";
@import "containers/app-listing";
@import "containers/device-registration-wizard";
@import "containers/submit-review";
@import "containers/request-app";
@import "containers/survey";
@import "containers/termination-fix";
@import "containers/enterprise-installation-tutorial";
@import "containers/terms";
@import "containers/support";
@import "components/payment-options";
@import "components/download-kit";
@import "components/device-registration";
@import "components/add-to-home-screen-message-container";
@import "components/error-boundary";
@import "components/dialog";
@import "components/not-found";
@import "components/network-error-message";
@import "components/purchase-button-action";
@import "components/campaign-dialog";
@import "components/update-kit-modal";
@import "components/campagin-modal";
@import "components/photography-event.scss";
@import "components/instagram-live-campaign-modal";
@import "components/modal-with-gif";
@import "components/app-downloader-v2.scss";

@import "swiper.scss";
@import "toastify";
@import "rating-bar";

body {
  position: relative;
  background-color: #fff;
  @media (prefers-color-scheme: dark) {
    background-color: $dark-mode-background-color;
  }
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);

  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

* {
  color: $text-color;
  @media (prefers-color-scheme: dark) {
    color: $dark-mode-text-color;
  }
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.overlay {
  background: #fff;
  @media (prefers-color-scheme: dark) {
    background-color: $dark-mode-background-color;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9000;
}

.separator {
  border: 1px solid $separators-color;
  @media (prefers-color-scheme: dark) {
    border-color: $dark-mode-separator-color;
  }
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  p {
    color: $text-gray_color;
    @media (prefers-color-scheme: dark) {
      color: $dark-mode-text-color;
    }
    padding-top: 5px;
  }
}

.loading-spinner.absolute {
  position: absolute;
}

.tab-bar {
  direction: ltr;
  text-align: center;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 56px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  @media (prefers-color-scheme: dark) {
    background-color: $dark-mode-tab-bar-color;
  }

  .tab-bar-item {
    flex: 0 0 20%;
    max-width: 20%;
    text-align: center;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin-top: 2px;
      margin-bottom: 3px;
      height: 18px;
    }

    p {
      font-size: 11px;
      margin-top: 3px;
      text-align: center;
      color: $text-gray_color;
      @media (prefers-color-scheme: dark) {
        color: $dark-mode-text-gray-color;
      }
    }
  }

  .tab-bar-item.active {
    img {
      filter: invert(30%) sepia(65%) saturate(2493%) hue-rotate(200deg)
        brightness(90%) contrast(93%);
    }

    p {
      color: $color-primary;
      @media (prefers-color-scheme: dark) {
        color: $color-primary;
      }
    }
  }
}

.iphone-x {
  @media screen and (orientation: portrait) {
    .tab-bar {
      height: 56px + 34px;

      .tab-bar-item {
        margin-top: 5px;
        margin-bottom: auto;
      }
    }
  }
}

.download-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    width: 75px;
    height: 30px;
    border-radius: 15px;
    background-color: #f2f2f8;
    border: none;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: $color-primary;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-button-background-color;
    }
  }

  button[disabled] {
    background-color: #8e8e93;
    color: #ffffff;
    /** TODO: Set dark mode color **/
  }
}

.hidden {
  display: none;
}

.back-button {
  float: left;
  background-image: url("/images/icon-back.svg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 56px;
  height: 44px;
}

.view-navigation-bar {
  position: fixed;
  width: 100%;
  height: 54px;
  background: #fff;
  @media (prefers-color-scheme: dark) {
    background: $dark-mode-background-color;
  }
  z-index: 1000;

  a {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  h1 {
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
  }
}

.color-red {
  color: $color-red;
}

.modal-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

:root {
  theme-color: black;
}

.h_iframe-aparat_embed_frame {
  position: relative;
}
.h_iframe-aparat_embed_frame .ratio {
  display: block;
  width: 100%;
  height: auto;
}
.h_iframe-aparat_embed_frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.apple-event-guide-button {
  background: $color-primary;
  border: none;
  margin: 16;
  color: #fff;
  height: 40px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  border-radius: 15px;
}
