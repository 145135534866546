.enterprise-installation-tutorial {
  .sections {
    padding-top: 64px;

    .section {
      padding: 16px;
      img {
        width: 100%;
      }

      h1 {
        font-size: 22px;
      }
    }
  }
}