.download-kit {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .kit-logo {
    width: 64px;
    height: 64px;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $color-secondary;
    @media (prefers-color-scheme: dark) {
      border-color: $dark-mode-separator-color;
    }
  }

  p {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    font-weight: normal;

    b {
      font-size: 16px;
      font-weight: bold;
    }
  }

  button {
    margin: 0px auto;
    background: $color-primary;
    color: #fff;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    border-radius: 15px;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
  }

  button[disabled] {
    background-color: $color-gray;
  }

  .install-kit-button {
    margin: 16px auto;
    background: #2ed5ce;
  }

  .next-step {
    position: absolute;
    width: 100%;
    left: 0;
    height: 160px;
    bottom: 0;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  label {
    font-size: 12px;
    font-weight: normal;
    color: $color-primary;
  }

  .form-group {
    margin-top: 5px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  input {
    margin-left: 15px;
    margin-top: 4px;
  }

  .step {
    color: $color-primary;
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: bold;
  }
}
