.login {
  position: relative;
  height: 90vh;

  .header {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 54px;
    background: #fff;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }

    a {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      direction: ltr;

      span {
        display: inline-table;
        vertical-align: middle;
        margin-top: -14px;
        color: $color-primary;

      }
    }

  }

  .container {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;

      label {
        font-size: 13px;
        text-align: right;
        margin-right: 15px;
        margin-top: 10px;
        color: $text-gray_color;
        @media (prefers-color-scheme: dark) {
          color: $dark-mode-text-gray-color;
        }
        margin-bottom: 2px;
      }

      label.error {
        color: $color-red;
      }

      input[type=tel] {
        border-radius: 5px;
        text-align: center;
        height: 46px;
        margin-right: 15px;
        margin-left: 15px;
        font-size: 17px;
        letter-spacing: 1.96px;
        border: solid 1px $separators-color;
        background-color: $color-fields-background;
        color: #000;
        @media (prefers-color-scheme: dark) {
          border-color: $dark-mode-separator-color;
          background-color: $dark-mode-separator-color;
          color: $dark-mode-text-color;
        }
      }

      input[type=submit], button[type=submit] {
        -webkit-appearance: none;
        height: 46px;
        margin: 20px 15px 15px;
        border-radius: 5px;
        border: none;
        background: $color-primary;
        color: #fff;
        text-align: center;
        font-size: 17px;
      }

      input.error {
        border-color: $color-red;
      }

      button[disabled] {
        background-color: $text-gray_color;
      }
    }

    h2 {
      text-align: center;
      font-size: 22px;
      margin-bottom: 8px;
    }

    p {
      text-align: center;
      font-size: 15px;
      color: $text-gray_color;
      padding-left: 15px;
      padding-right: 15px;

      .terms-button {
        color: $color-primary;
      }
    }

    .time {
      font-size: 17px;
      font-weight: bold;
      color: $color-primary;
    }

    .send-code-button {
      font-weight: bold;
      text-align: center;
      color: $color-primary;
      font-size: 14px;
    }
  }
}