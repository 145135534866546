.survey-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.6);
  @media (prefers-color-scheme: dark) {
    background: rgba(1, 1, 1, 0.4);;
  }

  .survey {
    margin-top: 100px;
    background: #fff;
    height: calc(100% - 155px);
    border-top: 4px solid $color-secondary;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: 15px 15px 40px;
    overflow-y: scroll;

    @media (prefers-color-scheme: dark) {
      background: $dark-mode-background-color;
      border-top: 2px solid $dark-mode-separator-color;
    }


    h1 {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .question {
      border-bottom: 1px solid $separators-color;
      @media (prefers-color-scheme: dark) {
        border-bottom: 1px solid $dark-mode-separator-color;
      }
      padding-bottom: 15px;
      margin-bottom: 15px;

      p {
        font-size: 14px;
      }

      p.q {
        font-size: 16px;
        font-weight: bold;
      }

      p.q:before {
        content: '🔔';
        padding-left: 5px;
      }

      p.error {
        color: $color-red;
        font-size: 12px;
      }

      .answers {
        margin-top: 15px;
        list-style: none;
        margin-right: 25px;
        input {
          margin-bottom: auto;
          margin-top: 5px;
          flex: 1;
        }
        p {
          display: inline;
          margin-right: 15px;
          margin-top: auto;
          margin-bottom: auto;
          flex: 20;
          color: $text-gray_color;
          @media (prefers-color-scheme: dark) {
            color: $dark-mode-text-gray-color;
          }
        }

        li {
          margin-bottom: 5px;
          display: flex;
          flex-direction: row;
        }
      }
    }

    button {
      background-color: $color-primary;
      width: 100%;
      padding: 15px;
      text-align: center;
      border-radius: 5px;
      border: none;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }

    .actions {
      display: flex;
      justify-content: left;
      margin-bottom: 10px;
      .cancel-button {
        color: $color-primary;
        margin-right: auto;
      }
    }
  }
}