.campaign-modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000;
  padding: 0 16px;
  box-sizing: border-box;

  .campaign-modal {
    max-width: 400px;
    margin: 16px auto;
    background-color: #fff;
    padding: 0 16px;
    border-radius: 8px;
    box-sizing: border-box;

    .campaign-modal-header {
      padding:10px 0;
      border-bottom: 1px solid $color-placeholder;
      font-size: 18px;
      font-weight: bold;
      color: #000;
      text-align: center;
    }

    .campaign-modal-body {
      padding: 16px 0;
      color: #000;
      text-align: center;

      img {
        display: block;
        width: auto;
        height: 64px;
        margin: auto auto 16px;
        border-radius: 15px;
      }

      img.banner {
        width: 100%;
        height: auto;
      }

      p, strong {
        color: #000;
      }

      p {
        margin-bottom: 16px;
        text-align: center;
      }

      input {
        width: 100%;
        height: 40px;
        border: 1px solid $color-gray;
        padding: 0 16px;
        box-sizing: border-box;
        text-align: center;
        direction: ltr;
        color: #000;
        font-size: 16px;
        border-radius: 5px;
      }

      .error {
        color: $color-red;
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: 5px;
        text-align: right;
      }

      .success {
        text-align: center;
        color: $color-primary;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .campaign-modal-footer {
      padding: 16px 0;
      border-top: 1px solid $color-placeholder;
      text-align: center;

      .btn {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        margin-left: 16px;
      }

      .btn[disabled] {
        background-color: $color-gray !important;
        border-color: $color-gray !important;
      }

      .btn-primary {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
      }

      .modal-footer>* {
        margin: .25rem;
      }

      .btn-danger {
        color: #fff;
        background-color: #dc3545;
        border-color:s #dc3545;
      }
    }
  }
}