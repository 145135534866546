$background-color: #fff;
$primary-blue: #186be7;

.photography-modal-container {
    .full-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.06);
        backdrop-filter: blur(8px);
        z-index: 1000;

        .custom-modal {
            width: 78%;
            position: absolute;
            left: 50%;
            top: 50vh;
            transform: translate(-50%, -50%);
            background-image: url("/images/photography-event/background.png");
            background-color: $background-color;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
            border-radius: 20px;
            pointer-event: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            padding: 30px 19px;
            height: 448px;

            div:first-child {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;

                .iapps {
                    width: 94px;
                    height: 16px;
                }
                .close {
                    width: 30px;
                    height: 30px;
                    background-color: rgba(245, 245, 245, 0.7);
                    border-radius: 50%;
                }
            }

            .main-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                .main-img {
                    width: 254px;
                    height: 254px;
                }

                .title {
                    color: $primary-blue;
                    font-size: 30px;
                    font-weight: 700;
                }
                .subtitle {
                    color: #717376;
                    font-weight: 700;
                }
            }

            .buttons {
                display: flex;
                width: 100%;
                height: 52px;
                justify-content: center;

                button {
                    border-radius: 12px;
                    text-align: center;
                    font-weight: 700;
                }
                .conditions {
                    width: 58%;
                    background-color: $primary-blue;
                    color: #fff;
                    border: none;
                }
            }
        }
    }
}
