.add-to-home-screen-message-container {
  img.background-photo {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
  }

  .content {
    background-color: #ffffff;
    color: #000;
    height: 50%;
    padding: 15px;
    text-align: center;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
      color: #fff;
    }
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 30px);

    h3 {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 24px;
    }

    .manual {
      text-align: center;
      img {
        width: 18px;
        height: auto;
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .icon-container {
      text-align: center;
      margin-top: 15px;

      .icon {
        @media (prefers-color-scheme: dark) {
          background-color: #fff;
          border-radius: 5px;
        }
        width: 50px;
      }
    }

    .actions {
      margin-top: 15px;
      a {
        display: block;
        text-align: center;
        color: $color-primary;
        font-size: 17px;
      }

      a.support {
        color: #000;
        font-size: 14px;
        margin-top: 15px;
        @media (prefers-color-scheme: dark) {
          color: #fff
        }
      }
    }
  }
}

.os-not-support, .browser-not-support {
  margin-top: 100px;

  p, a {
    text-align: center;
    direction: rtl;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: $color-red;
  }

  .description {
    font-size: 16px;
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }

  a {
    display: block;
    margin-top: 15px;
    color: $color-primary;
    font-size: 18px;
    font-weight: bold;
  }
}