.network-error-message {
  height: 90vh;

  .content {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 48px);
    text-align: center;

    p, a {
      text-align: center;
    }

    .title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .message {
      font-size: 14px;
      margin-bottom: 15px;
      color: $text-gray_color;
      @media (prefers-color-scheme: dark) {
        color: $dark-mode-text-gray-color;
      }
    }

    a {
      color: $color-primary;
    }
  }
}