.campagin-modal {
	.full-modal {
		.custom-modal {
			width: 350px;
			height: 500px;
			background-image: unset;
		}

		.modal-image {
			img {
				z-index: -10;
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		h1 {
			text-align: center;
			margin-top: 30px;
			color: #191A1A;
			font-size: 18px;
			font-weight: 700px;
		}

		p {
			padding-top: 16px;
			padding-bottom:0;
			margin-bottom:40px;
			color: #4B4D4D;
			font-size: 16px;
			font-weight: 700;
		}

		a.primary-button {
			display: relative;
			font-weight: 700;
			font-size: 16px;
			color: #ffffff;
			text-align: center;

			padding: 14px 60px;

			background: #186BE7;
			box-shadow: 0px 6px 26px rgba(16, 30, 115, 0.06);
			border-radius: 12px;
		}

		.modal-header {
			a {
				width: 30px;
				height: 30px;

				margin-top: 10px;
				margin-right: 20px;

				background: #F5F5F5;
				border-radius: 50px;
			}
		}
	}
}