.error-boundary {
  position: relative;
  height: 95vh;

  .background {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -20%);
    width: 100%;
  }

  .content {
    position: absolute;
    bottom: 0;
    height: 50%;
    padding: 15px;

    p, h3 {
      text-align: center;
    }

    h3 {
      margin-bottom: 15px;
    }

    .actions {
      a {
        display: block;
        text-align: center;
      }

      button {
        display: block;
        text-align: center;
        background: $color-primary;
        height: 50px;
        width: 100%;
        border: none;
        border-radius: 8px;
        margin: 15px 0;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
}


.private-mode-message {
  p {
    margin-top: 30px;
    text-align: center;
    font-size: 20px;
    color: $color-gray;
  }
}