@import url(/node_modules/swiper/css/swiper.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  direction: rtl;
  text-align: right;
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/eot/iranyekanwebboldfanum.eot");
  src: url("/fonts/eot/iranyekanwebboldfanum.eot?#iefix") format("embedded-opentype"), url("/fonts/woff/iranyekanwebboldfanum.woff") format("woff"), url("/fonts/ttf/iranyekanwebboldfanum.ttf") format("truetype");
}
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/eot/iranyekanwebthinfanum.eot");
  src: url("/fonts/eot/iranyekanwebthinfanum.eot?#iefix") format("embedded-opentype"), url("/fonts/woff/iranyekanwebthinfanum.woff") format("woff"), url("/fonts/ttf/iranyekanwebthinfanum.ttf") format("truetype");
}
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/eot/iranyekanweblightfanum.eot");
  src: url("/fonts/eot/iranyekanweblightfanum.eot?#iefix") format("embedded-opentype"), url("/fonts/woff/iranyekanweblightfanum.woff") format("woff"), url("/fonts/ttf/iranyekanweblightfanum.ttf") format("truetype");
}
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/eot/iranyekanwebregularfanum.eot");
  src: url("/fonts/eot/iranyekanwebregularfanum.eot?#iefix") format("embedded-opentype"), url("/fonts/woff/iranyekanwebregularfanum.woff") format("woff"), url("/fonts/ttf/iranyekanwebregularfanum.ttf") format("truetype");
}
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/eot/iranyekanwebmediumfanum.eot");
  src: url("/fonts/eot/iranyekanwebmediumfanum.eot?#iefix") format("embedded-opentype"), url("/fonts/woff/iranyekanwebmediumfanum.woff") format("woff"), url("/fonts/ttf/iranyekanwebmediumfanum.ttf") format("truetype");
}
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/eot/iranyekanwebextraboldfanum.eot");
  src: url("/fonts/eot/iranyekanwebextraboldfanum.eot?#iefix") format("embedded-opentype"), url("/fonts/woff/iranyekanwebextraboldfanum.woff") format("woff"), url("/fonts/ttf/iranyekanwebextraboldfanum.ttf") format("truetype");
}
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/eot/iranyekanwebblackfanum.eot");
  src: url("/fonts/eot/iranyekanwebblackfanum.eot?#iefix") format("embedded-opentype"), url("/fonts/woff/iranyekanwebblackfanum.woff") format("woff"), url("/fonts/ttf/iranyekanwebblackfanum.ttf") format("truetype");
}
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 950;
  src: url("/fonts/eot/iranyekanwebextrablackfanum.eot");
  src: url("/fonts/eot/iranyekanwebextrablackfanum.eot?#iefix") format("embedded-opentype"), url("/fonts/woff/iranyekanwebextrablackfanum.woff") format("woff"), url("/fonts/ttf/iranyekanwebextrablackfanum.ttf") format("truetype");
}
*, b {
  font-family: iranyekan, serif;
}

.featured-apps {
  padding-top: 100px;
  padding-bottom: 60px;
}
.featured-apps .header {
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 28px;
  z-index: 200;
}
@media (prefers-color-scheme: dark) {
  .featured-apps .header {
    background-color: #202435;
  }
}
.featured-apps .header h1 {
  font-size: 30px;
  padding-right: 15px;
  transition: font-size 200ms;
}
.featured-apps .header.small h1 {
  font-size: 20px;
}
.featured-apps .banner-section .scroller {
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 20px;
  width: 100%;
}
.featured-apps .banner-section .scroller .swiper-slide {
  position: relative;
  display: inline-table;
  width: calc(100% - 45px);
}
@media screen and (min-width: 500px) {
  .featured-apps .banner-section .scroller .swiper-slide {
    max-width: 500px;
  }
}
.featured-apps .banner-section .scroller .banner {
  display: inline-table;
  width: 100%;
}
.featured-apps .banner-section .scroller .banner .label {
  font-size: 11px;
  font-weight: bold;
  color: #027aff;
}
.featured-apps .banner-section .scroller .banner .title {
  font-size: 22px;
  max-width: 90vw;
  text-overflow: ellipsis;
  overflow: hidden;
}
.featured-apps .banner-section .scroller .banner .subtitle {
  font-size: 17px;
  margin-bottom: 5px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .featured-apps .banner-section .scroller .banner .subtitle {
    color: #7a7b7c;
  }
}
.featured-apps .banner-section .scroller .banner img {
  width: 100%;
  height: calc((100vw - 45px) / 1.3);
  min-height: calc((100vw - 45px) / 1.3);
  margin-top: 5px;
  border-radius: 14px;
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
}
@media (prefers-color-scheme: dark) {
  .featured-apps .banner-section .scroller .banner img {
    background-color: rgba(168, 180, 247, 0.12);
    border-color: rgba(168, 180, 247, 0.12);
  }
}
@media screen and (min-width: 500px) {
  .featured-apps .banner-section .scroller .banner img {
    min-height: calc((33.3333333333vw - 45px) / 1.3);
    height: auto;
  }
}
.featured-apps .single-app-section .section-header {
  display: flex;
  justify-content: space-between;
}
.featured-apps .single-app-section .section-header h1 {
  font-size: 20px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.featured-apps .single-app-section .section-header a {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16px;
  color: #027aff;
  font-weight: bold;
  font-size: 16px;
}
.featured-apps .single-app-section .scroller {
  padding-right: 15px;
  white-space: nowrap;
  overflow-y: hidden;
  display: flex;
  padding-bottom: 15px;
}
.featured-apps .single-app-section .scroller .single-app:last-child {
  padding-left: 15px;
}
.featured-apps .single-app-section .scroller .single-app {
  width: 160px;
  margin-left: 15px;
}
.featured-apps .single-app-section .scroller .single-app img {
  width: 160px;
  height: 160px;
  border-radius: 36px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
}
@media (prefers-color-scheme: dark) {
  .featured-apps .single-app-section .scroller .single-app img {
    border-color: rgba(168, 180, 247, 0.12);
    background-color: rgba(168, 180, 247, 0.12);
  }
}
.featured-apps .single-app-section .scroller .single-app .info {
  margin-top: 10px;
}
.featured-apps .single-app-section .scroller .single-app .info .title {
  width: 160px;
  font-size: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.featured-apps .single-app-section .scroller .single-app .info .category {
  font-size: 14px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .featured-apps .single-app-section .scroller .single-app .info .category {
    color: #7a7b7c;
  }
}
.featured-apps .triad-section {
  overflow: hidden;
}
.featured-apps .triad-section .section-header {
  display: flex;
  justify-content: space-between;
}
.featured-apps .triad-section .section-header h1 {
  font-size: 20px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.featured-apps .triad-section .section-header a {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16px;
  color: #027aff;
  font-weight: bold;
  font-size: 16px;
}
.featured-apps .triad-section .triad-swiper-container {
  width: 100%;
}
.featured-apps .triad-section .swiper-wrapper {
  white-space: nowrap;
  display: flex;
  padding-bottom: 15px;
}
.featured-apps .triad-section .app-group {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 45px);
}
@media screen and (min-width: 500px) {
  .featured-apps .triad-section .app-group {
    width: calc(33.3333333333vw - 45px);
  }
}
.featured-apps .app-with-secreenshot-section {
  overflow: hidden;
}
.featured-apps .app-with-secreenshot-section .section-header {
  display: flex;
  justify-content: space-between;
}
.featured-apps .app-with-secreenshot-section .section-header h1 {
  font-size: 20px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.featured-apps .app-with-secreenshot-section .section-header a {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16px;
  color: #027aff;
  font-weight: bold;
  font-size: 16px;
}
.featured-apps .app-with-secreenshot-section .scroller .swiper-wrapper {
  white-space: nowrap;
  display: flex;
  padding-bottom: 15px;
}
.featured-apps .categories-section {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-bottom: 20px;
}
.featured-apps .categories-section .section-header {
  display: flex;
  justify-content: space-between;
}
.featured-apps .categories-section .section-header h1 {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.featured-apps .categories-section .section-header a {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16px;
  color: #027aff;
  font-weight: bold;
  font-size: 16px;
}

.category-item {
  display: flex;
  align-items: center;
}
.category-item img {
  width: 30px;
  height: 30px;
  margin-left: 15px;
}
.category-item p {
  padding: 13px 0;
  flex: 1 1;
  font-size: 17px;
}

.app-with-screenshot {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 45px);
}
@media screen and (min-width: 500px) {
  .app-with-screenshot {
    width: 350px;
  }
}
.app-with-screenshot .triad-app {
  margin-left: auto;
}
.app-with-screenshot .screenshots {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.app-with-screenshot .screenshots div {
  background-size: 120%;
  background-position: center;
  background-color: #eeeeee;
  width: 106px;
  height: 188px;
  border-radius: 8px;
  align-self: center;
}
@media (prefers-color-scheme: dark) {
  .app-with-screenshot .screenshots div {
    background-color: rgba(168, 180, 247, 0.12);
  }
}

.app-with-screenshot:last-child {
  padding-left: 15px;
}

.triad-app {
  margin-top: 10px;
  margin-bottom: 0;
  width: calc(100vw - 45px);
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  align-self: center;
}
@media screen and (min-width: 500px) {
  .triad-app {
    width: calc(33.3333333333vw - 45px);
  }
}
.triad-app a {
  display: flex;
  flex: 1 1;
}
@media screen and (min-width: 500px) {
  .triad-app a {
    width: calc(33.3333333333vw - 110px);
  }
}
.triad-app img {
  width: 66px;
  height: 66px;
  border-radius: 14px;
  margin-top: 7px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
}
@media (prefers-color-scheme: dark) {
  .triad-app img {
    border-color: rgba(168, 180, 247, 0.12);
    background-color: rgba(168, 180, 247, 0.12);
  }
}
.triad-app .icon-wrapper {
  position: relative;
}
.triad-app .icon-wrapper .icon-badge {
  position: absolute;
  width: 20px;
  height: 20px;
  border: none;
  left: -10px;
  top: -10px;
}
.triad-app button {
  margin-top: 0;
}
.triad-app .info {
  margin-right: 10px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}
@media screen and (min-width: 500px) {
  .triad-app .info {
    max-width: calc(33.3333333333vw - 110px);
  }
}
.triad-app .info .title {
  font-size: 15px;
  width: calc(100vw - 200px);
  overflow: hidden;
  white-space: pre-line;
  max-height: 50px;
  text-overflow: ellipsis;
}
@media screen and (min-width: 500px) {
  .triad-app .info .title {
    width: calc(33.3333333333vw - 150px);
  }
}
.triad-app .info .category {
  font-size: 14px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .triad-app .info .category {
    color: #7a7b7c;
  }
}
.triad-app button {
  align-self: flex-start;
}

.developer-app .download-button button {
  background-color: #fff;
}

.search {
  position: relative;
  padding-bottom: 60px;
  min-height: 85vh;
}
.search h1 {
  font-size: 30px;
  margin-top: 20px;
  margin-right: 15px;
}
.search .scroller {
  padding-right: 15px;
  padding-bottom: 15px;
}
.search form {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.search form input[type=text] {
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 6px;
  border-radius: 8px;
  height: 46px;
  background-color: #f2f2f8;
  color: #000;
  border: none;
  padding-right: 15px;
  font-size: 16px;
}
@media (prefers-color-scheme: dark) {
  .search form input[type=text] {
    color: #ffffff;
    background-color: rgba(168, 180, 247, 0.12);
  }
}
.search form button.search-button {
  position: absolute;
  left: 25px;
  top: 55%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: transparent;
  border: none;
  width: 20px;
  height: 20px;
}
.search form button.search-button img {
  width: 20px;
  height: 20px;
}
.search h2 {
  font-size: 22px;
  margin-top: 20px;
  margin-right: 15px;
}
.search .trends {
  padding-top: 110px;
}
.search .search-trend {
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-size: 17px;
  color: #027aff;
  margin-right: 15px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  margin-left: 15px;
}
@media (prefers-color-scheme: dark) {
  .search .search-trend {
    border-bottom-color: rgba(168, 180, 247, 0.12);
  }
}
.search .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 100;
  padding-bottom: 5px;
}
@media (prefers-color-scheme: dark) {
  .search .header {
    background: #202435;
  }
}
.search .not-found-error {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.login {
  position: relative;
  height: 90vh;
}
.login .header {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 54px;
  background: #fff;
}
@media (prefers-color-scheme: dark) {
  .login .header {
    background-color: #202435;
  }
}
.login .header a {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  direction: ltr;
}
.login .header a span {
  display: inline-table;
  vertical-align: middle;
  margin-top: -14px;
  color: #027aff;
}
.login .container {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}
.login .container form {
  display: flex;
  flex-direction: column;
}
.login .container form label {
  font-size: 13px;
  text-align: right;
  margin-right: 15px;
  margin-top: 10px;
  color: #8e8e93;
  margin-bottom: 2px;
}
@media (prefers-color-scheme: dark) {
  .login .container form label {
    color: #7a7b7c;
  }
}
.login .container form label.error {
  color: #ff3a30;
}
.login .container form input[type=tel] {
  border-radius: 5px;
  text-align: center;
  height: 46px;
  margin-right: 15px;
  margin-left: 15px;
  font-size: 17px;
  letter-spacing: 1.96px;
  border: solid 1px #eeeeee;
  background-color: #f8f9fc;
  color: #000;
}
@media (prefers-color-scheme: dark) {
  .login .container form input[type=tel] {
    border-color: rgba(168, 180, 247, 0.12);
    background-color: rgba(168, 180, 247, 0.12);
    color: #ffffff;
  }
}
.login .container form input[type=submit], .login .container form button[type=submit] {
  -webkit-appearance: none;
  height: 46px;
  margin: 20px 15px 15px;
  border-radius: 5px;
  border: none;
  background: #027aff;
  color: #fff;
  text-align: center;
  font-size: 17px;
}
.login .container form input.error {
  border-color: #ff3a30;
}
.login .container form button[disabled] {
  background-color: #8e8e93;
}
.login .container h2 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 8px;
}
.login .container p {
  text-align: center;
  font-size: 15px;
  color: #8e8e93;
  padding-left: 15px;
  padding-right: 15px;
}
.login .container p .terms-button {
  color: #027aff;
}
.login .container .time {
  font-size: 17px;
  font-weight: bold;
  color: #027aff;
}
.login .container .send-code-button {
  font-weight: bold;
  text-align: center;
  color: #027aff;
  font-size: 14px;
}

.profile {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
}
@-webkit-keyframes pulse {
  0% {
    background-color: #f0f0f0; /* Light gray */
  }
  50% {
    background-color: #d0d0d0; /* Medium gray */
  }
  100% {
    background-color: #f0f0f0; /* Light gray */
  }
}
@keyframes pulse {
  0% {
    background-color: #f0f0f0; /* Light gray */
  }
  50% {
    background-color: #d0d0d0; /* Medium gray */
  }
  100% {
    background-color: #f0f0f0; /* Light gray */
  }
}
.profile .profile-loading {
  width: 100%;
  align-self: center;
  opacity: 0.2;
  height: 200px;
  margin: 8px;
  -webkit-animation: pulse 1.5s infinite ease-in-out;
          animation: pulse 1.5s infinite ease-in-out;
}
.profile .header {
  padding-top: 30px;
  padding-right: 15px;
}
.profile .header h1 {
  font-size: 17px;
}
.profile .header .username {
  color: #8e8e93;
  font-size: 14px;
}
@media (prefers-color-scheme: dark) {
  .profile .header .username {
    color: #7a7b7c;
  }
}
.profile .user-subscription-package {
  background-color: #f2f2f8;
  margin-top: 20px;
  padding: 20px 15px;
  height: auto;
}
@media (prefers-color-scheme: dark) {
  .profile .user-subscription-package {
    background-color: rgba(168, 180, 247, 0.12);
  }
}
.profile .user-subscription-package .subscription-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.profile .user-subscription-package .subscription-header h3 {
  font-size: 17px;
  align-self: center;
}
.profile .user-subscription-package .subscription-header .balance {
  background-color: #027aff;
  padding: 3px 4px;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  height: 20px;
  align-self: center;
}
.profile .user-subscription-package .subscription-header .blocked {
  background-color: #ff3a30;
  padding: 3px 4px;
  width: 96px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  height: 20px;
  align-self: center;
}
.profile .user-subscription-package .subscription-header .registered {
  padding: 3px 4px;
  width: 96px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  height: 20px;
  align-self: center;
  background-color: #3fc045;
}
.profile .user-subscription-package .subscription-header .not-registered {
  padding: 3px 4px;
  width: 96px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  height: 20px;
  align-self: center;
  background-color: #ff9800;
}
.profile .user-subscription-package .subscription-header .processing {
  padding: 3px 4px;
  width: 96px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  height: 26px;
  align-self: center;
  background-color: #ff9800;
}
.profile .user-subscription-package .subscription-header .disabled {
  padding: 3px 4px;
  width: 96px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  height: 20px;
  align-self: center;
  background-color: #8e8e93;
}
.profile .user-subscription-package .subscription-header .device-status {
  display: flex;
  flex-direction: column;
}
.profile .user-subscription-package .subscription-header .device-status .device-status__label {
  font-size: 10px;
  text-align: center;
  margin-bottom: 1px;
}
.profile .active-account-alert {
  padding: 8px;
  border-radius: 10px;
  background: #fff;
}
@media (prefers-color-scheme: dark) {
  .profile .active-account-alert {
    background-color: #202435;
  }
}
.profile .active-account-alert button {
  height: 54px;
  background: #027aff;
  border: none;
  border-radius: 10px;
  width: 100%;
  align-self: center;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.profile .active-account-alert .message {
  font-size: 14px;
  padding: 5px;
}
.profile ul.menu {
  margin-top: 20px;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
}
.profile ul.menu li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}
.profile ul.menu li img {
  width: 23px;
  align-self: center;
}
.profile ul.menu li div {
  display: flex;
  flex-direction: row;
}
.profile ul.menu li h3 {
  font-size: 17px;
  padding-right: 20px;
}
.profile ul.menu li h2 {
  margin-top: 0;
}

.no-subscription-packages {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.no-subscription-packages a {
  margin: auto;
}
.no-subscription-packages p {
  text-align: center;
  color: #8e8e93;
  font-size: 14px;
  margin-top: 10px;
}
.no-subscription-packages a.button {
  background-color: #027aff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  margin-top: 15px;
}

.active-package {
  background-color: #027aff;
  border-radius: 24px;
  padding: 16px;
  margin-bottom: 12px;
  min-height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.active-package .details h3 {
  color: #fff;
  margin-bottom: 8px;
}
.active-package .details .description p {
  color: #fff;
  font-size: 12px;
}

.account-close-button {
  color: #027aff;
  position: absolute;
  left: 15px;
  top: 15px;
  font-weight: bold;
  z-index: 1001;
}

.app-details .view-navigation-bar .app-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 36px;
  height: 36px;
  background-color: #eeeeee;
  border-radius: 12px;
  border: 1px solid #f2f2f8;
}
@media (prefers-color-scheme: dark) {
  .app-details .view-navigation-bar .app-logo {
    background-color: #202435;
    border-color: rgba(168, 180, 247, 0.12);
  }
}
.app-details .view-navigation-bar .button {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #027aff;
  border: none;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  height: 25px;
  border-radius: 15px;
}
.app-details .view-navigation-bar button[disabled], .app-details .view-navigation-bar .button[disabled] {
  background-color: #8e8e93;
  color: #ffffff;
}
.app-details .container {
  padding-top: 60px;
}
.app-details .container .basic-info {
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .basic-info .app-items {
  display: flex;
  flex-direction: row;
}
.app-details .container .basic-info .app-items .app-icon img {
  width: 112px;
  height: 112px;
  border-radius: 28px;
  border: solid 1px #f2f2f8;
  background-color: #eeeeee;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .basic-info .app-items .app-icon img {
    background-color: rgba(168, 180, 247, 0.12);
    border-color: rgba(168, 180, 247, 0.12);
  }
}
.app-details .container .basic-info .app-items .details {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  margin-bottom: 10px;
}
.app-details .container .basic-info .app-items .details h1 {
  font-size: 24px;
  text-align: right;
}
.app-details .container .basic-info .app-items .details p {
  font-size: 14px;
  color: #8e8e93;
  margin-bottom: 10px;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .basic-info .app-items .details p {
    color: #7a7b7c;
  }
}
.app-details .container .basic-info .app-items .details .button {
  text-align: center;
  max-width: 75px;
  height: 30px;
  border-radius: 15px;
  border: none;
  color: #fff;
  font-size: 14px;
  margin-top: auto;
  min-width: 75px;
  background-color: #027aff;
}
.app-details .container .basic-info .app-items .details a.button {
  padding-top: 5px;
  height: 25px;
}
.app-details .container .basic-info .app-items .details .button[disabled] {
  background-color: #8e8e93;
  color: #ffffff;
}
.app-details .container .basic-info .share {
  align-self: flex-end;
  margin-bottom: 10px;
}
.app-details .container .basic-info .share .share-button {
  width: 30px;
  height: 30px;
  background: #027aff;
  text-align: center;
  border: none;
  border-radius: 15px;
}
.app-details .container .app-rating {
  min-height: 56px;
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .app-rating .rating {
  margin-top: 20px;
  display: flex;
}
.app-details .container .app-rating .rating .rate {
  font-size: 24px;
  font-weight: 500;
  text-align: right;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .app-rating .rating .rate {
    color: #7a7b7c;
  }
}
.app-details .container .app-rating .rating .widget-ratings {
  direction: ltr;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 6px;
}
.app-details .container .app-rating .rate-count {
  font-size: 14px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .app-rating .rate-count {
    color: #7a7b7c;
  }
}
.app-details .container .screenshots {
  margin-top: 15px;
}
.app-details .container .screenshots .scroller {
  white-space: nowrap;
  overflow: scroll;
  margin-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .screenshots .scroller img {
  width: 206px;
  height: 366px;
  margin-left: 15px;
  border-radius: 8px;
  background-color: #eeeeee;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .screenshots .scroller img {
    background-color: rgba(168, 180, 247, 0.12);
  }
}
.app-details .container .screenshots .scroller img.landscape {
  min-height: calc((100vw - 15px) / 1.77);
  height: auto;
  width: calc(100% - 15px);
}
.app-details .container .screenshots .separator {
  margin: 0;
}
.app-details .container .description {
  margin-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .description p {
  font-size: 14px;
}
.app-details .container .description .more-button {
  color: #027aff;
  display: block;
  text-align: left;
  font-weight: bold;
}
.app-details .container .developer {
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .developer .container {
  padding: 0;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.app-details .container .developer .container .details a {
  color: #027aff;
  font-size: 17px;
  font-weight: bold;
}
.app-details .container .developer .container .details p {
  font-size: 15px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .developer .container .details p {
    color: #7a7b7c;
  }
}
.app-details .container .developer .container a {
  margin-top: auto;
  margin-bottom: auto;
}
.app-details .container .developer .separator {
  margin: 15px 0 0;
}
.app-details .container .change-log {
  margin-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .change-log .container {
  padding: 0;
}
.app-details .container .change-log .container h3 {
  font-size: 18px;
  font-weight: 500;
}
.app-details .container .change-log .container .version-details {
  display: flex;
  justify-content: space-between;
}
.app-details .container .change-log .container .version-details p {
  font-size: 15px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .change-log .container .version-details p {
    color: #7a7b7c;
  }
}
.app-details .container .change-log .container .change-log-text {
  margin-top: 10px;
  font-size: 15px;
}
.app-details .container .change-log .container .more-button {
  color: #027aff;
  display: block;
  text-align: left;
  font-weight: bold;
}
.app-details .container .change-log .container .separator {
  margin: 20px 0 0;
}
.app-details .container .review-rating {
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .review-rating .review-rating-container {
  direction: rtl;
  padding-bottom: 40px;
}
.app-details .container .review-rating .review-rating-container h3 {
  font-size: 18px;
  margin-top: 20px;
}
.app-details .container .review-rating .review-rating-container .rating {
  float: left;
  width: 130px;
}
.app-details .container .review-rating .review-rating-container .rating .rate {
  font-size: 45px;
  text-align: center;
}
.app-details .container .review-rating .review-rating-container .rating .rate-count {
  color: #8e8e93;
  font-size: 15px;
  text-align: center;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .review-rating .review-rating-container .rating .rate-count {
    color: #7a7b7c;
  }
}
.app-details .container .review-rating .review-rating-container .star-ratings {
  margin-left: 130px;
  position: relative;
  padding-top: 20px;
}
.app-details .container .review-rating .review-rating-container .star-ratings .star-info {
  margin-top: -10px;
}
.app-details .container .review-rating .review-rating-container .star-ratings div .rc-progress-line {
  width: 60%;
  margin-left: 10px;
  height: 3px;
  margin-bottom: 4px;
}
.app-details .container .comments {
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .comments .scroller {
  overflow: scroll;
  white-space: nowrap;
}
.app-details .container .comments .scroller .comment {
  padding: 15px;
  border-radius: 14px;
  display: inline-table;
  background-color: #f2f2f8;
  margin-left: 15px;
  width: 280px;
  height: 214px;
  overflow: hidden;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .comments .scroller .comment {
    background-color: #1c1c1e;
  }
}
.app-details .container .comments .scroller .comment .header {
  display: flex;
  justify-content: space-between;
}
.app-details .container .comments .scroller .comment .header .title {
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  max-width: 180px;
}
.app-details .container .comments .scroller .comment .header .date {
  font-size: 11px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .comments .scroller .comment .header .date {
    color: #7a7b7c;
  }
}
.app-details .container .comments .scroller .comment .comment-rate {
  margin-top: 5px;
}
.app-details .container .comments .scroller .comment .text {
  width: 250px;
  white-space: pre-line;
  font-size: 15px;
  height: 100px;
}
.app-details .container .write-review {
  direction: ltr;
  margin-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .write-review a {
  font-size: 17px;
  font-weight: bold;
  text-align: right;
  color: #027aff;
}
.app-details .container .write-review a img {
  padding-left: 5px;
  margin-bottom: -6px;
}
.app-details .container .write-review .separator {
  margin: 20px 0 0;
}
.app-details .container .information {
  padding-right: 15px;
  padding-left: 15px;
}
.app-details .container .information .info-container h3 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.app-details .container .information .info-container .info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.app-details .container .information .info-container .info .title {
  font-size: 15px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .information .info-container .info .title {
    color: #7a7b7c;
  }
}
.app-details .container .information .info-container .info .value {
  font-size: 17px;
  font-weight: bold;
}
.app-details .triad-section {
  overflow: hidden;
}
.app-details .triad-section .section-header {
  display: flex;
  justify-content: space-between;
}
.app-details .triad-section .section-header h1 {
  font-size: 16px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.app-details .triad-section .section-header a {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16px;
  color: #027aff;
  font-weight: bold;
  font-size: 16px;
}
.app-details .triad-section .triad-swiper-container {
  width: 100%;
}
.app-details .triad-section .swiper-wrapper {
  white-space: nowrap;
  display: flex;
  padding-bottom: 15px;
}
.app-details .triad-section .app-group {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 45px);
}
@media screen and (min-width: 500px) {
  .app-details .triad-section .app-group {
    width: calc(33.3333333333vw - 45px);
  }
}
.app-details .developer-apps-section {
  background-color: #F2F2F8;
}
@media (prefers-color-scheme: dark) {
  .app-details .developer-apps-section {
    background-color: rgba(168, 180, 247, 0.12);
  }
}
.app-details .not-found-error {
  text-align: center;
  position: absolute;
  top: 120px;
}
.app-details .not-found-error img {
  margin-right: auto;
  width: 100%;
}
.app-details .not-found-error p {
  text-align: center;
}
.app-details .install-message {
  position: fixed;
  padding: 0 10px;
  margin-bottom: 30px;
  width: calc(100% - 20px);
  bottom: 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-details .install-message .arrow_box {
  display: flex;
  padding: 10px;
  align-items: center;
}
.app-details .install-message .arrow_box {
  position: relative;
  background-color: #f2f2f8;
  border: 4px solid #eee;
  box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  box-sizing: border-box;
  color: #333;
}
@media (prefers-color-scheme: dark) {
  .app-details .install-message .arrow_box {
    background-color: #464E54;
    border: 4px solid #464E54;
  }
}
.app-details .install-message .arrow_box:after, .app-details .install-message .arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.app-details .install-message .arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #eee;
  border-width: 10px;
  margin-left: -10px;
}
@media (prefers-color-scheme: dark) {
  .app-details .install-message .arrow_box:after {
    border-top-color: #464E54;
  }
}
.app-details .install-message .arrow_box:before {
  border-color: rgba(238, 238, 238, 0);
  border-top-color: #eee;
  border-width: 16px;
  margin-left: -16px;
}
@media (prefers-color-scheme: dark) {
  .app-details .install-message .arrow_box:before {
    border-top-color: #464E54;
  }
}
.app-details .install-message .arrow_box > img {
  height: 50px;
}
.app-details .install-message .manual img {
  height: 25px;
  padding: 0 7px;
}

.downloader-container .app-downloader {
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 550px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px #666;
}
@media (prefers-color-scheme: dark) {
  .downloader-container .app-downloader {
    background-color: #202435;
    box-shadow: 1px 1px 5px rgba(168, 180, 247, 0.12);
  }
}
.downloader-container .app-downloader .actions {
  display: flex;
  justify-content: space-between;
}
.downloader-container .app-downloader .actions a.cancel-button {
  color: #027aff;
}
.downloader-container .app-downloader .separator {
  margin: 15px 0 0;
}
.downloader-container .app-downloader .distribution-type {
  padding-top: 15px;
}
.downloader-container .app-downloader .distribution-type .separator {
  margin-bottom: 15px;
}
.downloader-container .app-downloader .distribution-type .expandable {
  display: flex;
  justify-content: space-between;
}
.downloader-container .app-downloader .distribution-type .expandable .info {
  display: flex;
  justify-content: right;
}
.downloader-container .app-downloader .distribution-type .expandable .info .icon {
  width: 46px;
  height: 46px;
  border-radius: 8px;
  background-color: #027aff;
  display: flex;
  justify-content: center;
}
.downloader-container .app-downloader .distribution-type .expandable .info .icon img {
  margin-top: auto;
  margin-bottom: auto;
}
.downloader-container .app-downloader .distribution-type .expandable .info .description {
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
}
.downloader-container .app-downloader .distribution-type .expandable .info .description h1 {
  font-size: 14px;
  font-weight: bold;
}
.downloader-container .app-downloader .distribution-type .expandable .info .description p {
  font-size: 11px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .downloader-container .app-downloader .distribution-type .expandable .info .description p {
    color: #7a7b7c;
  }
}
.downloader-container .app-downloader .distribution-type .expandable .info .download-button {
  align-self: center;
  margin-left: auto;
}
.downloader-container .app-downloader .distribution-type .expandable .download-button {
  background: none;
  border: none;
}
.downloader-container .app-downloader .distribution-type .expandable-content {
  display: none;
}
.downloader-container .app-downloader .distribution-type .expandable-content .content a {
  font-size: 14px;
  margin-top: 15px;
  color: #027aff;
}
.downloader-container .app-downloader .distribution-type .expandable-content .content p {
  font-size: 13px;
  margin-top: 10px;
}
.downloader-container .app-downloader .distribution-type .expandable-content .content .info {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.downloader-container .app-downloader .distribution-type .expandable-content .content .info .key {
  color: #8e8e93;
  font-size: 13px;
}
@media (prefers-color-scheme: dark) {
  .downloader-container .app-downloader .distribution-type .expandable-content .content .info .key {
    color: #7a7b7c;
  }
}
.downloader-container .app-downloader .distribution-type .expandable-content .content .info .value {
  font-size: 14px;
  font-weight: bold;
}
.downloader-container .app-downloader .distribution-type .expandable-content.expanded {
  display: unset;
}
.downloader-container .app-downloader .distribution-type.disabled {
  -webkit-filter: invert(57%) sepia(0%) saturate(2%) hue-rotate(152deg) brightness(94%) contrast(90%);
          filter: invert(57%) sepia(0%) saturate(2%) hue-rotate(152deg) brightness(94%) contrast(90%);
}
.downloader-container .app-downloader .all-options {
  color: #027aff;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.subscription-packages .header {
  position: fixed;
  width: 100%;
  height: 54px;
  background: #fff;
  z-index: 10;
}
@media (prefers-color-scheme: dark) {
  .subscription-packages .header {
    background-color: #202435;
  }
}
.subscription-packages .header a {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.subscription-packages .header h1 {
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.subscription-packages .packages-header {
  padding: 80px 15px 30px;
}
.subscription-packages .packages-header h1 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 20px;
}
.subscription-packages .packages-header p {
  color: #8e8e93;
  font-size: 15px;
}
@media (prefers-color-scheme: dark) {
  .subscription-packages .packages-header p {
    color: #7a7b7c;
  }
}
.subscription-packages .has-survey {
  padding-top: 130px;
}

.subscription-package,
.package-preview {
  margin: 15px;
}
.subscription-package .content,
.package-preview .content {
  background-color: #eeeeee;
  box-sizing: border-box;
  height: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-size: 130%;
  background-position: center;
  border-radius: 15px;
  margin: 15px 0;
  padding: 8px 15px;
}
@media (prefers-color-scheme: dark) {
  .subscription-package .content,
  .package-preview .content {
    background-color: rgba(168, 180, 247, 0.12);
  }
}
.subscription-package .content h2,
.package-preview .content h2 {
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding: 30px 0 20px;
}
.subscription-package .content p,
.package-preview .content p {
  color: #fff;
  text-align: center;
  font-size: 16px;
  padding: 0 0 20px;
}
.subscription-package .content p.hasVoucher,
.package-preview .content p.hasVoucher {
  text-decoration: line-through;
  padding-bottom: 10px;
  color: #8e8e93;
}
.subscription-package .content p.voucherPrice,
.package-preview .content p.voucherPrice {
  padding: 0;
  font-weight: bold;
}

.survey-message {
  position: fixed;
  z-index: 5;
  padding: 59px 5px 5px;
  background-color: #51d869;
  font-size: 14px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.survey-message a {
  color: #fff;
}
.survey-message b {
  color: #fff;
  font-weight: bold;
  font-size: 22px;
}

.package-preview.package-2 {
  background-image: url("/images/large-blue-background.png");
}

.package-preview.package-1 {
  background-image: url("/images/large-dark-blue-background.png");
}

.package-preview.package-0 {
  background-image: url("/images/large-green-background.png");
}

.package-preview.package-3 {
  background-image: url("/images/large-purple-background.png");
}

.package-preview.package-4 {
  background-image: url("/images/large-red-background.png");
}

.packages-container {
  padding: 24px 0px;
}
.packages-container .header {
  position: fixed;
  width: 100%;
  height: 54px;
  background: #fff;
  z-index: 10;
}
@media (prefers-color-scheme: dark) {
  .packages-container .header {
    background-color: #202435;
  }
}
.packages-container .header a {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.packages-container .header h1 {
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

.package-layout {
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 16px;
}
.package-layout .register-segue {
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.package-layout .register-segue .register-device-segue__title {
  font-size: 16px;
  text-align: center;
  font-weight: 900;
}
.package-layout .register-segue .register-device-segue__description {
  font-size: 14px;
  text-align: center;
  text-justify: initial;
  padding: 4px 8px;
  font-weight: 300;
}
.package-layout .register-segue button {
  display: block;
  background: #027aff;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  height: 46px;
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  color: #000;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}
.package-layout .register-segue .register-segue__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50%;
}
.package-layout .register-segue .register-segue__loading button {
  display: block;
  background: transparent;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  height: 46px;
  margin-top: 10px;
  border: none;
  color: #027aff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.package-layout .subscription-steps {
  margin-top: 18px;
}
.package-layout .subscription-steps img {
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.package-layout .subscription-steps p {
  font-size: 14px;
  margin-bottom: 5px;
}
.package-layout .subscription-steps p span {
  font-size: 22px;
  color: #027aff;
  font-weight: bold;
}
.package-layout .subscription-steps button {
  display: block;
  background: #027aff;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  height: 46px;
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  color: #000;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}
.package-layout .subscription-steps .loading {
  margin-top: 40px;
}
.package-layout .subscription-steps .loading p {
  color: #8e8e93;
  font-size: 14px;
  text-align: center;
}
@media (prefers-color-scheme: dark) {
  .package-layout .subscription-steps .loading p {
    color: #7a7b7c;
  }
}
.package-layout .subscription-steps a.checkToken {
  text-align: center;
  color: #000;
  background: #027aff;
  padding: 10px 5px;
  border-radius: 5px;
  display: block;
  margin-top: 60px;
}
.package-layout .progtrckr em {
  display: none;
}
.package-layout .progtrckr span {
  display: inline-table;
}
.package-layout .RSPBprogressBar {
  margin-top: 50px;
}
.package-layout .step-name {
  margin-top: -65px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
}
.package-layout .step-name span {
  position: absolute;
  left: 0;
  top: -17px;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 16px;
  background: #fff;
  border: 2px solid #027aff;
  font-size: 16px;
  padding-top: 7px;
  font-weight: bold;
  box-sizing: border-box;
}
@media (prefers-color-scheme: dark) {
  .package-layout .step-name span {
    background-color: #202435;
  }
}
.package-layout .step-name span.accomplished {
  color: #000;
  background: #027aff;
}
.package-layout .RSPBstep:first-child {
  margin-left: 30px;
}
.package-layout .RSPBstep:nth-child(2) .step-name span {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.package-layout .RSPBstep:nth-child(3) {
  padding-right: 65px;
}
.package-layout .RSPBstep:nth-child(3) .step-name span {
  left: 50%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.subscription-packages.v2 {
  padding: 10px 0;
}
.subscription-packages.v2 .button {
  background: #027aff;
  width: 100%;
  height: 44px;
  margin-top: 20px;
  border: none;
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
.subscription-packages.v2 .general-message {
  font-size: 10px;
  color: #8e8e93;
  margin-right: 10px;
  margin-top: 10px;
}
.subscription-packages.v2 .header {
  text-align: right;
  direction: rtl;
  position: relative;
  height: auto;
}
.subscription-packages.v2 .header h1 {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 0 15px;
}
.subscription-packages.v2 .header ul {
  list-style: none;
  padding: 0;
  margin: 12px 0 0;
}
.subscription-packages.v2 .header ul li {
  display: flex;
  border-bottom: 1px solid #f2f2f8;
  padding: 12px 15px;
}
@media (prefers-color-scheme: dark) {
  .subscription-packages.v2 .header ul li {
    border-bottom: 1px solid rgba(168, 180, 247, 0.12);
  }
}
.subscription-packages.v2 .header ul li .feature-icon {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(222deg, rgb(0, 203, 255) 0%, rgb(0, 100, 249) 100%);
}
.subscription-packages.v2 .header ul li .feature-icon img {
  width: 20px;
  height: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.subscription-packages.v2 .header ul li .feature-description {
  margin-right: 10px;
}
.subscription-packages.v2 .header ul li .feature-description h2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.subscription-packages.v2 .header ul li .feature-description p {
  font-size: 14px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .subscription-packages.v2 .header ul li .feature-description p {
    color: #7a7b7c;
  }
}
.subscription-packages.v2 .header ul li:last-child {
  border-bottom: none;
}
.subscription-packages.v2 .payment-options-container {
  z-index: 100;
}
.subscription-packages.v2 .header.back-button {
  position: absolute;
  top: 20px;
  left: 0;
}
.subscription-packages.v2 .header.back-button .back-button {
  width: 30px;
}
.subscription-packages.v2 .overlay {
  position: fixed;
  top: 0;
  left: 0;
}

.packages-list {
  margin-top: 18px;
  padding: 8px;
}
.packages-list h1 {
  font-size: 18px;
  font-weight: bold;
}
.packages-list .subscription-package {
  margin: 0;
}
.packages-list .subscription-package .package {
  position: relative;
  overflow: hidden;
  border-radius: 24px;
}
.packages-list .subscription-package .package .package-header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.packages-list .subscription-package .package .package-header-container .package-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.packages-list .subscription-package .package .package-header-container .package-header .detail {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.packages-list .subscription-package .package .package-header-container .package-header .detail h3 {
  color: #000;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
}
.packages-list .subscription-package .package .package-header-container .package-header .detail .description {
  opacity: 0.8;
  font-size: 12px;
  font-weight: 300;
  color: #000;
  text-align: right;
  word-wrap: break-word;
  max-width: 80%;
}
.packages-list .subscription-package .package .package-header-container .package-header .detail .reward {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.packages-list .subscription-package .package .package-header-container .package-header .detail .reward img {
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin-left: 8px;
}
.packages-list .subscription-package .package .package-header-container .package-header .detail .reward .title {
  font-size: 12px;
  padding: 0;
  color: #000;
}
.packages-list .subscription-package .package .package-header-container .package-header .price-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.packages-list .subscription-package .package .package-header-container .package-header .price-title > h3 {
  font-size: 22px;
  font-weight: bold;
}
.packages-list .subscription-package .package .package-header-container .package-header .originalPrice {
  font-weight: normal;
  font-size: 10px;
  padding: 0;
  color: #fff;
  background: #027aff;
  align-self: end;
  padding: 5px;
  border-radius: 15px;
}
.packages-list .subscription-package .package .package-header-container .package-header .prices {
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: center;
}
.packages-list .subscription-package .package .package-header-container .package-header .prices .price {
  font-size: 28px;
  color: #000;
  padding: 0;
  font-weight: 800;
}
.packages-list .subscription-package .package .package-header-container .package-header .prices .price > span {
  font-size: 18px;
}
.packages-list .subscription-package .package .package-header-container .package-header .prices .currency {
  opacity: 0.7;
  color: #000;
  padding: 0;
  font-size: 10px;
}

.app-listing .header {
  position: fixed;
  width: 100%;
  height: 54px;
  background: #fff;
}
@media (prefers-color-scheme: dark) {
  .app-listing .header {
    background-color: #202435;
  }
}
.app-listing .header a {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.app-listing .header h1 {
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.app-listing .items {
  padding: 70px 15px 15px;
}
.app-listing .app-listing-item {
  display: flex;
  margin-bottom: 24px;
}
.app-listing .app-listing-item img {
  display: block;
  max-width: 86px;
  max-height: 86px;
  min-width: 86px;
  min-height: 86px;
  width: auto;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 18px;
  margin-left: 15px;
}
@media (prefers-color-scheme: dark) {
  .app-listing .app-listing-item img {
    border-color: rgba(168, 180, 247, 0.12);
  }
}
.app-listing .app-listing-item .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.app-listing .app-listing-item .info .title {
  font-size: 17px;
  white-space: nowrap;
  width: 100%;
}
.app-listing .app-listing-item .info .category {
  font-size: 14px;
  color: #8e8e93;
  margin-bottom: 9px;
}
@media (prefers-color-scheme: dark) {
  .app-listing .app-listing-item .info .category {
    color: #7a7b7c;
  }
}

.category-listing .items {
  padding-top: 60px;
}
.category-listing .category-item {
  margin-right: 15px;
}

.device-registration-wizard {
  padding: 0px 2px;
}
.device-registration-wizard .device-registration-step {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.device-registration-wizard .device-registration-step img {
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-radius: 26px;
}
.device-registration-wizard .device-registration-step .step {
  font-size: 16px;
  font-weight: 900;
  padding: 8px 0px;
}
.device-registration-wizard .device-registration-step .step-content {
  font-size: 14px;
  font-weight: bold;
  padding: 20px 8px;
  margin-right: 8px;
}
.device-registration-wizard .device-registration-step button {
  display: block;
  background: #027aff;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 16px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  bottom: 0;
  margin-top: auto;
}
.device-registration-wizard .device-registration-step button[disabled] {
  background-color: #8e8e93;
  color: #ffffff;
  /** TODO: Set dark mode color **/
}
.device-registration-wizard .device-registration-step .loading {
  margin-top: 40px;
}
.device-registration-wizard .device-registration-step .loading p {
  color: #8e8e93;
  font-size: 14px;
  text-align: center;
}
@media (prefers-color-scheme: dark) {
  .device-registration-wizard .device-registration-step .loading p {
    color: #7a7b7c;
  }
}
.device-registration-wizard .device-registration-step a.checkToken {
  text-align: center;
  color: #fff;
  background: #027aff;
  padding: 10px 5px;
  border-radius: 10px;
  display: block;
  margin-top: 60px;
}
.device-registration-wizard .progtrckr em {
  display: none;
}
.device-registration-wizard .progtrckr span {
  display: inline-table;
}
.device-registration-wizard .RSPBprogressBar {
  margin-top: 50px;
}
.device-registration-wizard .step-name {
  margin-top: -65px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
}
.device-registration-wizard .step-name span {
  position: absolute;
  left: 0;
  top: -17px;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 16px;
  background: #fff;
  border: 2px solid #027aff;
  font-size: 16px;
  padding-top: 7px;
  font-weight: bold;
  box-sizing: border-box;
}
@media (prefers-color-scheme: dark) {
  .device-registration-wizard .step-name span {
    background-color: #202435;
  }
}
.device-registration-wizard .step-name span.accomplished {
  color: #fff;
  background: #027aff;
}
.device-registration-wizard .RSPBstep:first-child {
  margin-left: 35px;
}
.device-registration-wizard .RSPBstep:nth-child(2) .step-name span {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.device-registration-wizard .RSPBstep:nth-child(3) {
  padding-right: 65px;
}
.device-registration-wizard .RSPBstep:nth-child(3) .step-name span {
  left: 50%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.device-registration-wizard .container {
  padding-top: 20px;
  height: 100%;
}

.submit-review .header {
  position: fixed;
  width: 100%;
  height: 54px;
  background: #fff;
}
@media (prefers-color-scheme: dark) {
  .submit-review .header {
    background-color: #202435;
  }
}
.submit-review .header a {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.submit-review .header h1 {
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.submit-review form {
  direction: ltr;
  text-align: center;
  padding-top: 80px;
}
.submit-review form .widget-ratings {
  direction: ltr;
}
.submit-review form input, .submit-review form textarea {
  width: calc(100% - 30px);
  margin-top: 15px;
  border-radius: 5px;
  text-align: center;
  height: 46px;
  margin-right: 15px;
  margin-left: 15px;
  font-size: 17px;
  border: solid 1px #eeeeee;
  background-color: #f8f9fc;
}
@media (prefers-color-scheme: dark) {
  .submit-review form input, .submit-review form textarea {
    border-color: rgba(168, 180, 247, 0.12);
    background-color: rgba(168, 180, 247, 0.12);
  }
}
.submit-review form textarea {
  height: 150px;
  padding-top: 15px;
}
.submit-review form button, .submit-review form input[type=submit] {
  width: calc(100% - 30px);
  -webkit-appearance: none;
  height: 46px;
  margin: 20px 15px 15px;
  border-radius: 5px;
  border: none;
  background: #027aff;
  color: #fff;
  text-align: center;
  font-size: 17px;
}

.request-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
@media (prefers-color-scheme: dark) {
  .request-app {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
.request-app .app-request-form {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 15px;
}
@media (prefers-color-scheme: dark) {
  .request-app .app-request-form {
    background-color: #202435;
  }
}
.request-app .app-request-form .form-group {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.request-app .app-request-form .form-group label {
  margin-bottom: 5px;
  font-size: 14px;
}
.request-app .app-request-form .form-group input[type=text], .request-app .app-request-form .form-group textarea {
  border: 1px solid rgb(190, 197, 212);
  color: #000;
  border-radius: 5px;
  text-align: center;
  height: 44px;
}
@media (prefers-color-scheme: dark) {
  .request-app .app-request-form .form-group input[type=text], .request-app .app-request-form .form-group textarea {
    background-color: rgba(168, 180, 247, 0.12);
    border-color: rgba(168, 180, 247, 0.12);
    color: #ffffff;
  }
}
.request-app .app-request-form .form-group textarea {
  padding: 5px;
  height: 100px;
}
.request-app .app-request-form .button {
  background: #027aff;
  width: 100%;
  height: 44px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 70px;
  color: #fff;
}

.survey-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.6);
}
@media (prefers-color-scheme: dark) {
  .survey-container {
    background: rgba(1, 1, 1, 0.4);
  }
}
.survey-container .survey {
  margin-top: 100px;
  background: #fff;
  height: calc(100% - 155px);
  border-top: 4px solid #f2f2f8;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 15px 15px 40px;
  overflow-y: scroll;
}
@media (prefers-color-scheme: dark) {
  .survey-container .survey {
    background: #202435;
    border-top: 2px solid rgba(168, 180, 247, 0.12);
  }
}
.survey-container .survey h1 {
  font-size: 14px;
  margin-bottom: 15px;
}
.survey-container .survey .question {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
@media (prefers-color-scheme: dark) {
  .survey-container .survey .question {
    border-bottom: 1px solid rgba(168, 180, 247, 0.12);
  }
}
.survey-container .survey .question p {
  font-size: 14px;
}
.survey-container .survey .question p.q {
  font-size: 16px;
  font-weight: bold;
}
.survey-container .survey .question p.q:before {
  content: "🔔";
  padding-left: 5px;
}
.survey-container .survey .question p.error {
  color: #ff3a30;
  font-size: 12px;
}
.survey-container .survey .question .answers {
  margin-top: 15px;
  list-style: none;
  margin-right: 25px;
}
.survey-container .survey .question .answers input {
  margin-bottom: auto;
  margin-top: 5px;
  flex: 1 1;
}
.survey-container .survey .question .answers p {
  display: inline;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  flex: 20 1;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .survey-container .survey .question .answers p {
    color: #7a7b7c;
  }
}
.survey-container .survey .question .answers li {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
}
.survey-container .survey button {
  background-color: #027aff;
  width: 100%;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
.survey-container .survey .actions {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}
.survey-container .survey .actions .cancel-button {
  color: #027aff;
  margin-right: auto;
}

.report-account-problem {
  padding: 15px;
}
.report-account-problem img {
  width: 100%;
}
.report-account-problem h1 {
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
}
.report-account-problem p {
  text-align: right;
  font-size: 14px;
}
.report-account-problem a {
  display: block;
  text-align: center;
  margin-top: 25px;
  background-color: #027aff;
  height: 44px;
  border-radius: 22px;
  color: #fff;
  padding-top: 10px;
  box-sizing: border-box;
}
.report-account-problem input[type=phone] {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  text-align: center;
}
.report-account-problem form {
  padding-top: 15px;
}
.report-account-problem form label {
  color: #ff3a30;
  font-size: 12px;
  margin-bottom: 5px;
}

.enterprise-installation-tutorial .sections {
  padding-top: 64px;
}
.enterprise-installation-tutorial .sections .section {
  padding: 16px;
}
.enterprise-installation-tutorial .sections .section img {
  width: 100%;
}
.enterprise-installation-tutorial .sections .section h1 {
  font-size: 22px;
}

.terms .term-items {
  padding: 16px;
}
.terms .term-items p {
  margin-bottom: 16px;
  text-align: justify;
}
.terms h1 {
  padding-top: 64px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 16pt;
  color: #027aff;
}
.terms h4, .terms h5 {
  color: #027aff;
}
.terms strong {
  font-style: bold;
}

.container {
  background-color: #fff;
}
@media (prefers-color-scheme: dark) {
  .container {
    background-color: #202435;
  }
}
.container .header {
  position: fixed;
  width: 100%;
  height: 54px;
  background: #fff;
  z-index: 10;
}
@media (prefers-color-scheme: dark) {
  .container .header {
    background-color: #202435;
  }
}
.container .header a {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.container .header h1 {
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.container .content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 86vh;
  margin-top: 36px;
}
.container .content h3 {
  margin-bottom: 8px;
}
.container .content p {
  font-size: 12px;
  margin-top: 1px;
  text-align: justify;
  padding-top: 8px;
  font-weight: 300;
}
.container .content .buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.container .content .buttons .phone-button {
  text-align: center;
  border-radius: 12px;
  background-color: transparent;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  padding: 8px;
  border: "2px solid";
  border-color: #027aff;
}
.container .content .buttons .phone-button p {
  font-size: 14px;
  color: #027aff;
  margin-right: 8px;
  font-weight: 700;
}
.container .content .buttons .chat-button {
  text-align: center;
  border-radius: 12px;
  border: none;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  margin-bottom: 12px;
  margin-top: 12px;
  padding: 8px;
  background-color: #027aff;
}
.container .content .buttons .chat-button p {
  font-size: 14px;
  color: #fff;
  margin-right: 8px;
  font-weight: 700;
}
.container .content .buttons a {
  margin-top: 16px;
  color: #027aff;
  font-size: 14px;
}

.payment-options-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
@media (prefers-color-scheme: dark) {
  .payment-options-container {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
.payment-options-container .payment-options {
  z-index: 200;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 425px;
  width: 100%;
  background-color: #fff;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 30px 0px 0px;
  box-sizing: border-box;
}
@media (prefers-color-scheme: dark) {
  .payment-options-container .payment-options {
    background-color: #202435;
  }
}
.payment-options-container .payment-options .alerts {
  /* @media screen and (max-height: 667px) {
    margin-top: 32px;
  } */
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 8px;
}
.payment-options-container .payment-options .alerts p {
  font-family: iranyekan;
  padding-top: 2px;
  font-size: 12px;
  color: #8e8e93;
}
.payment-options-container .payment-options .alerts b {
  font-size: 14px;
  color: #8e8e93;
}
@media screen and (device-aspect-ratio: 40/71) {
  .payment-options-container .payment-options .alerts .alerts-title {
    display: none;
  }
}
.payment-options-container .payment-options .discount {
  position: relative;
  background-color: #f8f9fc;
  padding: 15px 15px 15px;
}
@media screen and (min-height: 667px) {
  .payment-options-container .payment-options .discount {
    margin-top: 10px;
  }
}
@media (prefers-color-scheme: dark) {
  .payment-options-container .payment-options .discount {
    background-color: rgba(168, 180, 247, 0.12);
  }
}
@media screen and (device-aspect-ratio: 40/71) {
  .payment-options-container .payment-options .discount {
    padding: 5px 5px 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .payment-options-container .payment-options .discount {
    padding: 10px 10px 10px;
  }
}
.payment-options-container .payment-options .discount a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.payment-options-container .payment-options .discount a .image {
  width: 46px;
  height: 34px;
  background-color: rgb(190, 197, 212);
  display: flex;
  justify-items: center;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
.payment-options-container .payment-options .discount a .image img {
  margin: auto;
  width: 16px;
  height: 16px;
}
.payment-options-container .payment-options .discount a .description .title {
  font-size: 17px;
  font-weight: bold;
}
.payment-options-container .payment-options .discount a .description .subtitle {
  font-size: 14px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .payment-options-container .payment-options .discount a .description .subtitle {
    color: #7a7b7c;
  }
}
.payment-options-container .payment-options .discount-field .title {
  font-size: 17px;
  font-weight: bold;
}
@media screen and (device-aspect-ratio: 40/71) {
  .payment-options-container .payment-options .discount-field .title {
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .payment-options-container .payment-options .discount-field .title {
    font-size: 14px;
  }
}
.payment-options-container .payment-options .discount-field form {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.payment-options-container .payment-options .discount-field form input[type=text] {
  flex: 1 1;
  border: 1px solid rgb(190, 197, 212);
  color: #000;
  margin-left: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 17px;
  letter-spacing: 2px;
}
@media (prefers-color-scheme: dark) {
  .payment-options-container .payment-options .discount-field form input[type=text] {
    background-color: #000000;
    border-color: rgba(168, 180, 247, 0.12);
    color: #ffffff;
  }
}
.payment-options-container .payment-options .discount-field form button[type=submit] {
  width: 75px;
  background-color: #8e8e93;
  border: none;
  height: 56px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .payment-options-container .payment-options .discount-field form button[type=submit] {
    height: 40px;
  }
}
@media screen and (device-aspect-ratio: 40/71) {
  .payment-options-container .payment-options .discount-field form button[type=submit] {
    height: 40px;
  }
}
.payment-options-container .payment-options .discount-field form button.active {
  background-color: #027aff;
}
.payment-options-container .payment-options .discount-field .success {
  background-color: rgba(2, 122, 255, 0.06);
  border-radius: 5px;
  display: flex;
  padding: 16px;
  margin-top: 5px;
}
.payment-options-container .payment-options .discount-field .success p {
  margin: auto;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #027aff;
}
@media screen and (device-aspect-ratio: 40/71) {
  .payment-options-container .payment-options .discount-field .success p {
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .payment-options-container .payment-options .discount-field .success p {
    font-size: 14px;
  }
}
.payment-options-container .payment-options .discount.error .discount-field .title {
  color: #ff3a30;
  font-size: 14px;
}
.payment-options-container .payment-options .progress {
  padding-top: 45px;
}
.payment-options-container .payment-options .progress .values {
  display: flex;
  justify-content: space-between;
}
.payment-options-container .payment-options .progress .values .final {
  font-size: 14px;
  color: #027aff;
}
.payment-options-container .payment-options .progress .values .total {
  font-size: 14px;
  color: #8e8e93;
}
.payment-options-container .payment-options .actions .button {
  margin-top: 15px;
  background-color: #027aff;
  color: #fff;
  width: 100%;
  height: 56px;
  border: none;
  text-align: center;
  border-radius: 10px;
  font-size: 17px;
  font-weight: bold;
}
.payment-options-container .payment-options.show-discount {
  height: 460px;
}
.payment-options-container .packages-list {
  /* @media screen and (max-height: 667px) {
     display: none;
  } */
}
.payment-options-container .packages-list .subscription-package .content {
  margin-bottom: 0;
  padding: 0px 15px;
}

.gateway-redirection {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 250;
}
@media (prefers-color-scheme: dark) {
  .gateway-redirection {
    background-color: #202435;
  }
}

.v2 .payment-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  top: 0;
  border-radius: 0;
  padding-top: 5px;
  background-color: #ffffff;
}
@media (prefers-color-scheme: dark) {
  .v2 .payment-options {
    background-color: #202435;
  }
}
.v2 .payment-options .back-button-wrapper {
  text-align: left;
  direction: ltr;
}
.v2 .payment-options .package-preview {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-radius: 10px;
  margin: 40px 15px 5px;
}
.v2 .payment-options .package-preview .content {
  background-color: unset;
  border-radius: 10px;
  height: 148px;
  margin-bottom: 5px;
}
@media screen and (device-aspect-ratio: 40/71) {
  .v2 .payment-options .package-preview .content {
    height: 90px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .v2 .payment-options .package-preview .content {
    height: 130px;
  }
}
.v2 .payment-options .package-preview .content .basic-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.v2 .payment-options .package-preview .content .description {
  font-size: 10px;
  margin-top: 10px;
  text-align: right;
}
.v2 .payment-options .package-preview .content h2 {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-family: iranyekan, sans-serif;
}
.v2 .payment-options .package-preview .content p {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-family: iranyekan, sans-serif;
}
.v2 .payment-options .package-preview .content p span {
  color: #fff;
  font-size: 11px;
}
.v2 .payment-options.show-discount {
  height: 100%;
}
.v2 .payment-options-details {
  padding-bottom: 150px;
  overflow-y: auto;
}
.v2 .actions {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}
@media (prefers-color-scheme: dark) {
  .v2 .actions {
    background: #202435;
  }
}
.v2 .actions .prices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.v2 .actions .prices .key {
  font-size: 13px;
  margin: 5px;
  color: #8e8e93;
  font-family: iranyekan, sans-serif;
}
@media (prefers-color-scheme: dark) {
  .v2 .actions .prices .key {
    color: #7a7b7c;
  }
}
.v2 .actions .prices .value {
  font-size: 17px;
  font-weight: bold;
  font-family: iranyekan, sans-serif;
}
.v2 .actions .prices .value.normal-weight {
  font-size: 13px;
  font-weight: normal;
}
.v2 .actions .prices-divider {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
}
@media screen and (max-height: 667px) {
  .v2 .actions {
    padding: 8px 15px;
  }
  .v2 .actions .prices-divider {
    margin-bottom: 2px;
  }
  .v2 .actions .button {
    max-height: 35px;
  }
}
.v2 .gateway-list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: streach;
  grid-gap: 8px;
  gap: 8px;
}
@media screen and (max-height: 667px) {
  .v2 .gateway-list {
    margin-top: 16px;
  }
}
.v2 .gateway-list h4 {
  margin: 0 8px;
  font-weight: bold;
  font-size: 14px;
}
.v2 .gateway-list button.active {
  border: 2px solid #027aff;
}
.v2 .gateway-list button {
  background: #fff;
  margin: 0 8px;
  padding: 4px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}
@media screen and (min-height: 667px) {
  .v2 .gateway-list button {
    padding: 2px 4px;
  }
}
@media (prefers-color-scheme: dark) {
  .v2 .gateway-list button {
    background: #202435;
    border: 1px solid rgba(168, 180, 247, 0.12);
  }
}
.v2 .gateway-list button .gateway {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.v2 .gateway-list button .gateway img {
  width: 60px;
  height: 60px;
  padding: 8px;
  box-sizing: border-box;
  object-fit: contain;
}
@media screen and (max-height: 667px) {
  .v2 .gateway-list button .gateway img {
    width: 40px;
    height: 40px;
  }
}
.v2 .gateway-list button .gateway .gateway-info h6 {
  font-size: 16px;
  font-weight: bold;
}
.v2 .gateway-list button .gateway .gateway-info p {
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .v2 .gateway-list button .gateway .gateway-info p {
    color: #ffffff;
  }
}

.download-kit {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.download-kit .kit-logo {
  width: 64px;
  height: 64px;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #f2f2f8;
}
@media (prefers-color-scheme: dark) {
  .download-kit .kit-logo {
    border-color: rgba(168, 180, 247, 0.12);
  }
}
.download-kit p {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  font-weight: normal;
}
.download-kit p b {
  font-size: 16px;
  font-weight: bold;
}
.download-kit button {
  margin: 0px auto;
  background: #027aff;
  color: #fff;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
}
.download-kit button[disabled] {
  background-color: #8e8e93;
}
.download-kit .install-kit-button {
  margin: 16px auto;
  background: #2ed5ce;
}
.download-kit .next-step {
  position: absolute;
  width: 100%;
  left: 0;
  height: 160px;
  bottom: 0;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.download-kit label {
  font-size: 12px;
  font-weight: normal;
  color: #027aff;
}
.download-kit .form-group {
  margin-top: 5px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.download-kit input {
  margin-left: 15px;
  margin-top: 4px;
}
.download-kit .step {
  color: #027aff;
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: bold;
}

.device-registration {
  background: #f8f9fc;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
}
@media (prefers-color-scheme: dark) {
  .device-registration {
    background-color: #202435;
  }
}
.device-registration .back-button {
  position: fixed;
  left: 0;
  top: 0;
}
.device-registration .content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}
.device-registration .content h1, .device-registration .content p {
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;
}
.device-registration .content h1 {
  margin-top: 48px;
  font-size: 22px;
}
.device-registration .content p {
  margin-top: 5px;
  font-size: 14px;
}
.device-registration .content button {
  height: 54px;
  background: #027aff;
  border: none;
  border-radius: 10px;
  margin-left: 24px;
  margin-right: 24px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-size: 17px;
  margin-top: 10px;
}
.device-registration .content img {
  width: 100%;
}
.device-registration .content a {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #027aff;
}

.add-to-home-screen-message-container img.background-photo {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, -20%);
          transform: translate(-50%, -20%);
}
.add-to-home-screen-message-container .content {
  background-color: #ffffff;
  color: #000;
  height: 50%;
  padding: 15px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 30px);
}
@media (prefers-color-scheme: dark) {
  .add-to-home-screen-message-container .content {
    background-color: #202435;
    color: #fff;
  }
}
.add-to-home-screen-message-container .content h3 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
}
.add-to-home-screen-message-container .content .manual {
  text-align: center;
}
.add-to-home-screen-message-container .content .manual img {
  width: 18px;
  height: auto;
  padding-left: 8px;
  padding-right: 8px;
}
.add-to-home-screen-message-container .content .icon-container {
  text-align: center;
  margin-top: 15px;
}
.add-to-home-screen-message-container .content .icon-container .icon {
  width: 50px;
}
@media (prefers-color-scheme: dark) {
  .add-to-home-screen-message-container .content .icon-container .icon {
    background-color: #fff;
    border-radius: 5px;
  }
}
.add-to-home-screen-message-container .content .actions {
  margin-top: 15px;
}
.add-to-home-screen-message-container .content .actions a {
  display: block;
  text-align: center;
  color: #027aff;
  font-size: 17px;
}
.add-to-home-screen-message-container .content .actions a.support {
  color: #000;
  font-size: 14px;
  margin-top: 15px;
}
@media (prefers-color-scheme: dark) {
  .add-to-home-screen-message-container .content .actions a.support {
    color: #fff;
  }
}

.os-not-support, .browser-not-support {
  margin-top: 100px;
}
.os-not-support p, .os-not-support a, .browser-not-support p, .browser-not-support a {
  text-align: center;
  direction: rtl;
}
.os-not-support .title, .browser-not-support .title {
  font-size: 18px;
  font-weight: bold;
  color: #ff3a30;
}
.os-not-support .description, .browser-not-support .description {
  font-size: 16px;
  margin-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}
.os-not-support a, .browser-not-support a {
  display: block;
  margin-top: 15px;
  color: #027aff;
  font-size: 18px;
  font-weight: bold;
}

.error-boundary {
  position: relative;
  height: 95vh;
}
.error-boundary .background {
  position: absolute;
  left: 50%;
  top: 20%;
  -webkit-transform: translate(-50%, -20%);
          transform: translate(-50%, -20%);
  width: 100%;
}
.error-boundary .content {
  position: absolute;
  bottom: 0;
  height: 50%;
  padding: 15px;
}
.error-boundary .content p, .error-boundary .content h3 {
  text-align: center;
}
.error-boundary .content h3 {
  margin-bottom: 15px;
}
.error-boundary .content .actions a {
  display: block;
  text-align: center;
}
.error-boundary .content .actions button {
  display: block;
  text-align: center;
  background: #027aff;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 8px;
  margin: 15px 0;
  font-size: 17px;
  font-weight: bold;
}

.private-mode-message p {
  margin-top: 30px;
  text-align: center;
  font-size: 20px;
  color: #8e8e93;
}

.dialog-container {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
}
@media (prefers-color-scheme: dark) {
  .dialog-container {
    background: rgba(255, 255, 255, 0.4);
  }
}
.dialog-container .dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: calc(100% - 48px);
  background: #fff;
  overflow: hidden;
  border-radius: 15px;
}
@media (prefers-color-scheme: dark) {
  .dialog-container .dialog {
    background: #202435;
  }
}
.dialog-container .dialog .content {
  padding: 24px;
  box-sizing: border-box;
}
.dialog-container .dialog .content h3, .dialog-container .dialog .content p {
  text-align: center;
}
.dialog-container .dialog .content h3 {
  margin-bottom: 5px;
}
.dialog-container .dialog .actions button {
  width: 50%;
  background: none;
  border: 1px solid #eeeeee;
  border-bottom: none;
  text-align: center;
  padding: 15px 0;
  font-size: 16px;
}
@media (prefers-color-scheme: dark) {
  .dialog-container .dialog .actions button {
    border-color: rgba(168, 180, 247, 0.12);
  }
}
.dialog-container .dialog .actions .negative {
  border-right: none;
  font-weight: normal;
}
.dialog-container .dialog .actions .positive {
  font-weight: bold;
}

.not-found {
  position: relative;
  height: 85vh;
}
.not-found .background {
  position: absolute;
  left: 50%;
  top: 20%;
  -webkit-transform: translate(-50%, -20%);
          transform: translate(-50%, -20%);
  width: 100%;
}
.not-found .content {
  position: absolute;
  bottom: 0;
  height: 50%;
  padding: 15px;
}
.not-found .content p, .not-found .content h3 {
  text-align: center;
}
.not-found .content h3 {
  margin-bottom: 15px;
}
.not-found .content .actions {
  margin-top: 15px;
}
.not-found .content .actions a {
  display: block;
  text-align: center;
  color: #027aff;
  font-size: 17px;
  font-weight: bold;
}
.not-found .content .actions .support {
  display: block;
  text-align: center;
  margin-top: 15px;
  font-weight: normal;
  font-size: 14px;
  color: #000;
}
@media (prefers-color-scheme: dark) {
  .not-found .content .actions .support {
    color: #fff;
  }
}

.network-error-message {
  height: 90vh;
}
.network-error-message .content {
  position: absolute;
  left: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: calc(100% - 48px);
  text-align: center;
}
.network-error-message .content p, .network-error-message .content a {
  text-align: center;
}
.network-error-message .content .title {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}
.network-error-message .content .message {
  font-size: 14px;
  margin-bottom: 15px;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .network-error-message .content .message {
    color: #7a7b7c;
  }
}
.network-error-message .content a {
  color: #027aff;
}

.purchase-package-action {
  position: fixed;
  bottom: 56px;
  background-color: #027aff;
  width: 100vw;
  height: 35px;
  text-align: center;
  padding-top: 12px;
  color: #fff;
  font-weight: bold;
}

@media screen and (orientation: portrait) {
  .iphone-x .purchase-package-action {
    bottom: 90px;
  }
}

.campaign-modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000;
  padding: 0 16px;
  box-sizing: border-box;
}
.campaign-modal-container .campaign-modal {
  max-width: 400px;
  margin: 16px auto;
  background-color: #fff;
  padding: 0 16px;
  border-radius: 8px;
  box-sizing: border-box;
}
.campaign-modal-container .campaign-modal .campaign-modal-header {
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  text-align: center;
}
.campaign-modal-container .campaign-modal .campaign-modal-body {
  padding: 16px 0;
  color: #000;
  text-align: center;
}
.campaign-modal-container .campaign-modal .campaign-modal-body img {
  display: block;
  width: auto;
  height: 64px;
  margin: auto auto 16px;
  border-radius: 15px;
}
.campaign-modal-container .campaign-modal .campaign-modal-body img.banner {
  width: 100%;
  height: auto;
}
.campaign-modal-container .campaign-modal .campaign-modal-body p, .campaign-modal-container .campaign-modal .campaign-modal-body strong {
  color: #000;
}
.campaign-modal-container .campaign-modal .campaign-modal-body p {
  margin-bottom: 16px;
  text-align: center;
}
.campaign-modal-container .campaign-modal .campaign-modal-body input {
  width: 100%;
  height: 40px;
  border: 1px solid #8e8e93;
  padding: 0 16px;
  box-sizing: border-box;
  text-align: center;
  direction: ltr;
  color: #000;
  font-size: 16px;
  border-radius: 5px;
}
.campaign-modal-container .campaign-modal .campaign-modal-body .error {
  color: #ff3a30;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 5px;
  text-align: right;
}
.campaign-modal-container .campaign-modal .campaign-modal-body .success {
  text-align: center;
  color: #027aff;
  font-size: 16px;
  font-weight: bold;
}
.campaign-modal-container .campaign-modal .campaign-modal-footer {
  padding: 16px 0;
  border-top: 1px solid #eeeeee;
  text-align: center;
}
.campaign-modal-container .campaign-modal .campaign-modal-footer .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 16px;
}
.campaign-modal-container .campaign-modal .campaign-modal-footer .btn[disabled] {
  background-color: #8e8e93 !important;
  border-color: #8e8e93 !important;
}
.campaign-modal-container .campaign-modal .campaign-modal-footer .btn-primary {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.campaign-modal-container .campaign-modal .campaign-modal-footer .modal-footer > * {
  margin: 0.25rem;
}
.campaign-modal-container .campaign-modal .campaign-modal-footer .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: s #dc3545;
}

.full-modal-container .full-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.06);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  z-index: 1000;
}
.full-modal-container .full-modal .custom-modal {
  width: 300px;
  height: 335px;
  position: absolute;
  left: 50%;
  top: 50vh;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  background-image: url(/images/update-modal-header.jpg);
  background-size: 436px 148px;
  background-repeat: no-repeat;
  background-position: -100px -25px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  pointer-event: none;
}
.full-modal-container .modal-image img {
  z-index: 1001;
  position: absolute;
  top: -125px;
  right: 10px;
}
.full-modal-container .modal-header h3 {
  text-align: center;
  color: #1A93D8;
  font-weight: 800;
}
.full-modal-container .modal-header a {
  position: absolute;
  color: gray;
  top: 5px;
  left: 10px;
  font-size: 10pt;
  font-weight: 800;
}
.full-modal-container .modal-header .modal-logo {
  margin-top: 25px;
  margin-right: 20px;
}
.full-modal-container .modal-header .modal-featured-image {
  margin-top: 15px;
}
.full-modal-container .modal-header .modal-featured-image .texts {
  display: flex;
  justify-content: center;
}
.full-modal-container .modal-header .modal-featured-image .texts h5 {
  color: #4B4D4D;
  font-size: 14px;
  font-weight: 700;
}
.full-modal-container .modal-header .modal-featured-image .texts h5 strong {
  color: #4B4D4D;
  font-weight: 700;
  font-size: 22px;
}
.full-modal-container .modal-header .modal-featured-image .texts h5:first-child {
  margin-left: 43px;
}
.full-modal-container .modal-body {
  text-align: center;
}
.full-modal-container .modal-body p {
  padding: 24px;
  text-align: center;
  color: #8e8e93;
  font-weight: 400;
  margin-bottom: 15px;
}
.full-modal-container .modal-body p strong {
  color: #8e8e93;
  font-weight: 800;
}
.full-modal-container .modal-body .modal-primary-button {
  text-align: center;
  background-color: #ff3a30;
  color: #fff;
  max-height: 44px;
  padding: 8px;
  border-radius: 20px;
}
.full-modal-container .modal-body .support-link {
  color: #8e8e93;
  font-size: 14px;
}

.campagin-modal .full-modal .custom-modal {
  width: 350px;
  height: 500px;
  background-image: unset;
}
.campagin-modal .full-modal .modal-image img {
  z-index: -10;
  position: absolute;
  top: 0;
  right: 0;
}
.campagin-modal .full-modal h1 {
  text-align: center;
  margin-top: 30px;
  color: #191A1A;
  font-size: 18px;
  font-weight: 700px;
}
.campagin-modal .full-modal p {
  padding-top: 16px;
  padding-bottom: 0;
  margin-bottom: 40px;
  color: #4B4D4D;
  font-size: 16px;
  font-weight: 700;
}
.campagin-modal .full-modal a.primary-button {
  display: relative;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  padding: 14px 60px;
  background: #186BE7;
  box-shadow: 0px 6px 26px rgba(16, 30, 115, 0.06);
  border-radius: 12px;
}
.campagin-modal .full-modal .modal-header a {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-right: 20px;
  background: #F5F5F5;
  border-radius: 50px;
}

.photography-modal-container .full-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.06);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  z-index: 1000;
}
.photography-modal-container .full-modal .custom-modal {
  width: 78%;
  position: absolute;
  left: 50%;
  top: 50vh;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-image: url("/images/photography-event/background.png");
  background-color: #fff;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  pointer-event: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 30px 19px;
  height: 448px;
}
.photography-modal-container .full-modal .custom-modal div:first-child {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.photography-modal-container .full-modal .custom-modal div:first-child .iapps {
  width: 94px;
  height: 16px;
}
.photography-modal-container .full-modal .custom-modal div:first-child .close {
  width: 30px;
  height: 30px;
  background-color: rgba(245, 245, 245, 0.7);
  border-radius: 50%;
}
.photography-modal-container .full-modal .custom-modal .main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.photography-modal-container .full-modal .custom-modal .main-content .main-img {
  width: 254px;
  height: 254px;
}
.photography-modal-container .full-modal .custom-modal .main-content .title {
  color: #186be7;
  font-size: 30px;
  font-weight: 700;
}
.photography-modal-container .full-modal .custom-modal .main-content .subtitle {
  color: #717376;
  font-weight: 700;
}
.photography-modal-container .full-modal .custom-modal .buttons {
  display: flex;
  width: 100%;
  height: 52px;
  justify-content: center;
}
.photography-modal-container .full-modal .custom-modal .buttons button {
  border-radius: 12px;
  text-align: center;
  font-weight: 700;
}
.photography-modal-container .full-modal .custom-modal .buttons .conditions {
  width: 58%;
  background-color: #186be7;
  color: #fff;
  border: none;
}

@-webkit-keyframes start-animation {
  from {
    -webkit-transform: translate(100%, 100%) rotate(45deg);
            transform: translate(100%, 100%) rotate(45deg);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes start-animation {
  from {
    -webkit-transform: translate(100%, 100%) rotate(45deg);
            transform: translate(100%, 100%) rotate(45deg);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
}
.ig-live-campaign-modal-container {
  z-index: 2000;
  position: fixed;
  display: relative;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.39);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  text-align: left;
}
.ig-live-campaign-modal-container h1, .ig-live-campaign-modal-container p, .ig-live-campaign-modal-container a {
  color: #ffffff;
}
.ig-live-campaign-modal-container .animation {
  -webkit-animation: start-animation 1s 1 alternate;
          animation: start-animation 1s 1 alternate;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  text-align: left;
  direction: ltr;
}
.ig-live-campaign-modal-container .animation .full-image {
  position: absolute;
  z-index: -1;
  left: -387px;
  top: -59px;
}
.ig-live-campaign-modal-container img.background {
  margin-top: -60px;
}
.ig-live-campaign-modal-container .phone-content {
  position: absolute;
  top: 105px;
  left: 50px;
  padding: 0 35px;
  height: 550px;
}
.ig-live-campaign-modal-container .phone-content .header {
  display: flex;
  justify-content: space-between;
  width: 220px;
  align-items: center;
}
.ig-live-campaign-modal-container .phone-content .content {
  text-align: right;
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  width: 220px;
}
.ig-live-campaign-modal-container .phone-content .content h1 {
  font-size: 16pt;
  font-weight: 800;
}
.ig-live-campaign-modal-container .phone-content .content p {
  font-size: 12px;
  margin-bottom: 61px;
}
.ig-live-campaign-modal-container .phone-content .content a {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.31162px 10.8051px 8.31162px 13.2986px;
  grid-gap: 4.99px;
  gap: 4.99px;
  position: absolute;
  width: 206.96px;
  height: 36.62px;
  bottom: 0;
  background: rgba(24, 107, 231, 0.9);
  border: 0.831162px solid rgba(120, 180, 255, 0.5);
  box-shadow: 0px 4.98697px 35.7399px rgba(128, 146, 255, 0.6);
  border-radius: 9.97394px;
}
.ig-live-campaign-modal-container .phone-content .content .separator {
  width: 39.9px;
  height: 3.32px;
  margin-right: 0px;
  margin-bottom: 10px;
  background: linear-gradient(270deg, #1964D7 -0.67%, #2CD095 -0.66%, #FFFFFF 51.43%, #EE2742 102.45%);
  box-shadow: 16.6232px 3.32465px 8.31162px 0.831162px rgba(44, 208, 149, 0.4), 0px 1.66232px 8.31162px 0.831162px rgba(255, 255, 255, 0.4), -11.6363px 1.66232px 8.31162px 0.831162px rgba(255, 96, 96, 0.4);
  border-radius: 2.49348px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.ig-live-campaign-modal-container #close-button {
  background-color: gray;
  position: absolute;
  padding: 2px 5px;
  font-size: 10pt;
  font-weight: 600;
  top: -35px;
  right: 12px;
  text-align: center;
  border-radius: 10px;
}

@keyframes start-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal-with-gif-container {
  z-index: 2000;
  position: fixed;
  display: relative;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.39);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}
.modal-with-gif-container .modal-with-gif {
  position: absolute;
  width: 350px;
  height: 490px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 20px;
  -webkit-animation: start-animation 0.5s 1 alternate;
          animation: start-animation 0.5s 1 alternate;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.modal-with-gif-container .modal-with-gif .gif-header,
.modal-with-gif-container .modal-with-gif .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
}
.modal-with-gif-container .modal-with-gif .gif-header a,
.modal-with-gif-container .modal-with-gif .header a {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background: #f5f5f5;
  border-radius: 50px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #737373;
  font-weight: 800;
  font-size: 14pt;
}
.modal-with-gif-container .modal-with-gif .content .gif-container {
  margin-top: -70px;
}
.modal-with-gif-container .modal-with-gif .content .modal-body {
  padding: 8px;
}
.modal-with-gif-container .modal-with-gif .content .content-image {
  margin-top: 60px;
  max-width: 275px;
  height: auto;
}
.modal-with-gif-container .modal-with-gif .content .main-content {
  margin-top: -60px;
  padding: 0 38px;
}
.modal-with-gif-container .modal-with-gif .content .main-content .badge {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  grid-gap: 10px;
  gap: 10px;
  width: 90px;
  height: 25px;
  background: #ffda79;
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.modal-with-gif-container .modal-with-gif .content .main-content h1 {
  color: #111111;
  font-weight: 800;
  font-size: 20px;
  line-height: 35px;
  text-align: right;
}
.modal-with-gif-container .modal-with-gif .content .main-content p {
  color: #111111;
}
.modal-with-gif-container .modal-with-gif .content .main-content p span {
  color: #111111;
  font-weight: 800;
}
.modal-with-gif-container .modal-with-gif .content .main-content label {
  color: #111111;
}
.modal-with-gif-container .modal-with-gif .content .main-content .blue {
  background-color: #f3ec78;
  background-image: linear-gradient(0deg, #1d62f0, #1ad5fd);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.modal-with-gif-container .modal-with-gif .content .main-content .tip-container {
  display: flex;
  align-items: flex-end;
  margin-top: -13px;
  margin-bottom: 22px;
}
.modal-with-gif-container .modal-with-gif .content .main-content .tip-container .tip {
  color: #ff9800;
  font-size: 16px;
  font-weight: 700;
}
.modal-with-gif-container .modal-with-gif .content .main-content .code-container .code {
  background: #f5f5ff;
  border: 1px solid rgba(26, 29, 49, 0.12);
  border-radius: 12px 0px 12px 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 15px;
  margin-bottom: 15px;
  margin-top: 3px;
}
.modal-with-gif-container .modal-with-gif .content .main-content .code-container .code div {
  color: rgba(0, 0, 0, 0.54);
}
.modal-with-gif-container .modal-with-gif .content .main-content .code-container .code img {
  height: 17px;
  width: 15px;
  padding-left: 5px;
}
.modal-with-gif-container .modal-with-gif .content .main-content .code-container .code .icon {
  font-size: 10px;
  display: flex;
  align-items: center;
}
.modal-with-gif-container .modal-with-gif .content .main-content button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8.31162px 10.8051px 8.31162px 13.2986px;
  grid-gap: 4.99px;
  gap: 4.99px;
  margin-top: 100px;
  width: 274px;
  height: 42px;
  background: rgba(24, 107, 231, 0.9);
  border: 0.831162px solid rgba(120, 180, 255, 0.5);
  box-shadow: 0px 4.98697px 35.7399px rgba(128, 146, 255, 0.6);
  border-radius: 9.97394px;
  color: "white";
  flex: none;
  order: 1;
  flex-grow: 0;
  font-weight: 700;
  font-size: 12pt;
  line-height: 20px;
}

.app-downloader-v2 button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: rgba(76, 217, 100, 0.96) !important;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: rgba(255, 58, 48, 0.96) !important;
}

@media (prefers-color-scheme: dark) {
  .widget-svg:not(.multi-widget-selected):not(.widget-selected) .widget {
    fill: #2F3236 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .widget-selected .widget {
    fill: #8D8E93 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .widget-grad stop:nth-child(1) {
    stop-color: #8D8E93 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .widget-grad stop:nth-child(2) {
    stop-color: #8D8E93 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .widget-grad stop:nth-child(3) {
    stop-color: #2F3236 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .widget-grad stop:nth-child(4) {
    stop-color: #2F3236 !important;
  }
}

.app-details .container .comments .scroller .comment .comment-rate .widget-ratings, .submit-review .widget-ratings {
  direction: ltr;
}
@media (prefers-color-scheme: dark) {
  .app-details .container .comments .scroller .comment .comment-rate .widget-svg:not(.multi-widget-selected):not(.widget-selected) .widget, .submit-review .widget-svg:not(.multi-widget-selected):not(.widget-selected) .widget {
    fill: #2F3236 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .app-details .container .comments .scroller .comment .comment-rate .widget-selected .widget, .submit-review .widget-selected .widget {
    fill: #ff9502 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .app-details .container .comments .scroller .comment .comment-rate .widget-grad stop:nth-child(1), .submit-review .widget-grad stop:nth-child(1) {
    stop-color: #8D8E93 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .app-details .container .comments .scroller .comment .comment-rate .widget-grad stop:nth-child(2), .submit-review .widget-grad stop:nth-child(2) {
    stop-color: #8D8E93 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .app-details .container .comments .scroller .comment .comment-rate .widget-grad stop:nth-child(3), .submit-review .widget-grad stop:nth-child(3) {
    stop-color: #2F3236 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .app-details .container .comments .scroller .comment .comment-rate .widget-grad stop:nth-child(4), .submit-review .widget-grad stop:nth-child(4) {
    stop-color: #2F3236 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .rc-progress-line-trail {
    stroke: #2D2E31 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .rc-progress-line-path {
    stroke: #A2A2AB !important;
  }
}

@media (prefers-color-scheme: dark) {
  .payment-options .rc-progress-line-trail {
    stroke: #2D2E31 !important;
  }
}
@media (prefers-color-scheme: dark) {
  .payment-options .rc-progress-line-path {
    stroke: #027aff !important;
  }
}

body {
  position: relative;
  background-color: #fff;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  -webkit-user-select: none;
  user-select: none;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #202435;
  }
}

* {
  color: #111111;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
@media (prefers-color-scheme: dark) {
  * {
    color: #ffffff;
  }
}

input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.overlay {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9000;
}
@media (prefers-color-scheme: dark) {
  .overlay {
    background-color: #202435;
  }
}

.separator {
  border: 1px solid #eeeeee;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
}
@media (prefers-color-scheme: dark) {
  .separator {
    border-color: rgba(168, 180, 247, 0.12);
  }
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}
.loading-spinner p {
  color: #8e8e93;
  padding-top: 5px;
}
@media (prefers-color-scheme: dark) {
  .loading-spinner p {
    color: #ffffff;
  }
}

.loading-spinner.absolute {
  position: absolute;
}

.tab-bar {
  direction: ltr;
  text-align: center;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 56px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
@media (prefers-color-scheme: dark) {
  .tab-bar {
    background-color: #151823;
  }
}
.tab-bar .tab-bar-item {
  flex: 0 0 20%;
  max-width: 20%;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab-bar .tab-bar-item img {
  margin-top: 2px;
  margin-bottom: 3px;
  height: 18px;
}
.tab-bar .tab-bar-item p {
  font-size: 11px;
  margin-top: 3px;
  text-align: center;
  color: #8e8e93;
}
@media (prefers-color-scheme: dark) {
  .tab-bar .tab-bar-item p {
    color: #7a7b7c;
  }
}
.tab-bar .tab-bar-item.active img {
  -webkit-filter: invert(30%) sepia(65%) saturate(2493%) hue-rotate(200deg) brightness(90%) contrast(93%);
          filter: invert(30%) sepia(65%) saturate(2493%) hue-rotate(200deg) brightness(90%) contrast(93%);
}
.tab-bar .tab-bar-item.active p {
  color: #027aff;
}
@media (prefers-color-scheme: dark) {
  .tab-bar .tab-bar-item.active p {
    color: #027aff;
  }
}

@media screen and (orientation: portrait) {
  .iphone-x .tab-bar {
    height: 90px;
  }
  .iphone-x .tab-bar .tab-bar-item {
    margin-top: 5px;
    margin-bottom: auto;
  }
}

.download-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.download-button button {
  width: 75px;
  height: 30px;
  border-radius: 15px;
  background-color: #f2f2f8;
  border: none;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #027aff;
}
@media (prefers-color-scheme: dark) {
  .download-button button {
    background-color: #1b1a21;
  }
}
.download-button button[disabled] {
  background-color: #8e8e93;
  color: #ffffff;
  /** TODO: Set dark mode color **/
}

.hidden {
  display: none;
}

.back-button {
  float: left;
  background-image: url("/images/icon-back.svg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 56px;
  height: 44px;
}

.view-navigation-bar {
  position: fixed;
  width: 100%;
  height: 54px;
  background: #fff;
  z-index: 1000;
}
@media (prefers-color-scheme: dark) {
  .view-navigation-bar {
    background: #202435;
  }
}
.view-navigation-bar a {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.view-navigation-bar h1 {
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

.color-red {
  color: #ff3a30;
}

.modal-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

:root {
  theme-color: black;
}

.h_iframe-aparat_embed_frame {
  position: relative;
}

.h_iframe-aparat_embed_frame .ratio {
  display: block;
  width: 100%;
  height: auto;
}

.h_iframe-aparat_embed_frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.apple-event-guide-button {
  background: #027aff;
  border: none;
  margin: 16;
  color: #fff;
  height: 40px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  border-radius: 15px;
}
