.profile {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;

  // TODO: remove media queryes and fix issue without using media query
  // Fix iphone 5s/SE profile issue
  //@media screen and (device-aspect-ratio: 40/71) {
  //  height: calc(100vh + 170px);
  //}
  //
  //// Fix iphone 6 7 8 issue
  //@media only screen
  //and (min-device-width : 375px)
  //and (max-device-width : 667px)
  //and (width : 375px)
  //and (height : 667px) {
  //  height: calc(100vh + 110px);
  //}

  @keyframes pulse {
    0% {
      background-color: #f0f0f0; /* Light gray */
    }
    50% {
      background-color: #d0d0d0; /* Medium gray */
    }
    100% {
      background-color: #f0f0f0; /* Light gray */
    }
  }

  .profile-loading {
    width: 100%;
    align-self: center;
    opacity: 0.2;
    height: 200px;
    margin: 8px;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .header {
    padding-top: 30px;
    padding-right: 15px;

    h1 {
      font-size: 17px;
    }

    .username {
      color: $text-gray_color;
      @media (prefers-color-scheme: dark) {
        color: $dark-mode-text-gray-color;
      }
      font-size: 14px;
    }
  }

  .user-subscription-package {
    background-color: $color-secondary;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-separator-color;
    }
    margin-top: 20px;
    padding: 20px 15px;
    height: auto;

    .subscription-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;

      h3 {
        font-size: 17px;
        align-self: center;
      }

      .balance {
        background-color: $color-primary;
        padding: 3px 4px;
        border-radius: 20px;
        color: #fff;
        font-size: 14px;
        height: 20px;
        align-self: center;
      }

      .blocked {
        background-color: $color-red;
        padding: 3px 4px;
        width: 96px;
        text-align: center;
        border-radius: 20px;
        color: #fff;
        font-size: 14px;
        height: 20px;
        align-self: center;
      }

      .registered {
        padding: 3px 4px;
        width: 96px;
        text-align: center;
        border-radius: 20px;
        color: #fff;
        font-size: 14px;
        height: 20px;
        align-self: center;
        background-color: $color-success;
      }
      .not-registered {
        padding: 3px 4px;
        width: 96px;
        text-align: center;
        border-radius: 20px;
        color: #fff;
        font-size: 14px;
        height: 20px;
        align-self: center;
        background-color: $color-warning;
      }
      .processing {
        padding: 3px 4px;
        width: 96px;
        text-align: center;
        border-radius: 20px;
        color: #fff;
        font-size: 14px;
        height: 26px;
        align-self: center;
        background-color: $color-warning;
      }
      .disabled {
        padding: 3px 4px;
        width: 96px;
        text-align: center;
        border-radius: 20px;
        color: #fff;
        font-size: 14px;
        height: 20px;
        align-self: center;
        background-color: $color-gray;
      }
      .device-status {
        display: flex;
        flex-direction: column;
        .device-status__label {
          font-size: 10px;
          text-align: center;
          margin-bottom: 1px;
        }
      }
    }
  }

  .active-account-alert {
    padding: 8px;
    border-radius: 10px;
    background: #fff;
    @media (prefers-color-scheme: dark) {
      background-color: $dark-mode-background-color;
    }
    button {
      height: 54px;
      background: $color-primary;
      border: none;
      border-radius: 10px;
      width: 100%;
      align-self: center;
      box-sizing: border-box;
      text-align: center;
      color: #fff;
      font-size: 20px;
      font-weight: 800;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .message {
      font-size: 14px;
      padding: 5px;
    }
  }

  ul.menu {
    margin-top: 20px;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0px 15px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;

      img {
        width: 23px;
        align-self: center;
      }

      div {
        display: flex;
        flex-direction: row;
      }
      h3 {
        font-size: 17px;
        padding-right: 20px;
      }
      h2 {
        margin-top: 0;
      }
    }
  }
}

.no-subscription-packages {
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    margin: auto;
  }

  p {
    text-align: center;
    color: $text-gray_color;
    font-size: 14px;
    margin-top: 10px;
  }

  a.button {
    background-color: $color-primary;
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 14px;
    margin-top: 15px;
  }
}

.active-package {
  background-color: $color-primary;
  border-radius: 24px;
  padding: 16px;
  margin-bottom: 12px;
  min-height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .details {
    h3 {
      color: #fff;
      margin-bottom: 8px;
    }
    .description {
      p {
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.account-close-button {
  color: $color-primary;
  position: absolute;
  left: 15px;
  top: 15px;
  font-weight: bold;
  z-index: 1001;
}
